import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { AppDispatch, RootState } from "../../store";
import { SimpleCard } from "../card";
import { MissingCode, setPublishers } from "../common/pubRedux";
import { ApplicationItem } from "../common";

export const UnknownPublishers = () => {
  const [codes, setCodes] = useState<{pid:number, publisher:number}[]>([]);
  const {loading, error, data, missingCodes} = useSelector((s:RootState) => s.publishers);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setCodes([]);
  }, [missingCodes]);
  
  const handleOnChange = (pid:number, publisher:number|null) => {
    const index = codes.findIndex(c => c.pid === pid);
    if (!publisher) { 
      if (index >= 0) {
        codes.splice(index, 1);
        setCodes([...codes]);
      }
      return; 
    }    
    if (index >= 0) {
      codes[index].publisher = publisher;
    }
    else {
      codes.push({pid, publisher})
    }
    setCodes([...codes]);
  }

  const isDisabled = useMemo(() => {
    return loading || codes.length === 0
  }, [loading, codes]);

  // const toolbar = useMemo(() => [(
  //     <button key={1}
  //       className="btn btn-light-primary" 
  //       onClick={() => dispatch(setPublishers(codes))}
  //       disabled={isDisabled}
  //     >Apply</button>
  // )], [])

  return (
    <SimpleCard title="Unknown publisher codes" loading={loading} error={error} >
      <div className='table-responsive pb-5'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th>Application</th>
              <th>MMP</th>
              <th>OS</th>
              <th>Last seen</th>
              <th>PID</th>
              <th>Publisher</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {missingCodes && missingCodes.map((c:MissingCode) => (
              <tr key={c.id}>
                <td>
                  <ApplicationItem name={c.application.name} logo={c.application.logo} />
                  {/* <img src={c.application.logo} alt={c.application.name} height='40px'/> */}
                </td>
                <td>
                  <img src={`/media/custom/${c.mmp}-icon.png`} alt={c.mmp} height='25px'/>
                </td>
                <td>
                  <img src={`/media/custom/${c.os}.png`} alt={c.os} height='25px'/>
                </td>
                <td>{moment(c.last_event).format('DD/MM/YYYY')}</td>
                <td>{c.pid}</td>
                <td>
                  <Select
                    className="basic-single"
                    options={data || []}
                    getOptionLabel={o => o.name}
                    getOptionValue={o => o.id.toString()}
                    onChange={v => handleOnChange(c.id, v ? v.id : v)}
                    menuPortalTarget={document.body}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <button 
          className="btn btn-light-primary" 
          onClick={() => dispatch(setPublishers(codes))}
          disabled={isDisabled}
        >Apply</button>
      </div>
    </SimpleCard>

  )
}