import moment from 'moment';
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { KTSVG } from "../../_metronic/helpers";
import { SimpleCard } from "../card";
import { formatNumber } from "../../core/helpers";

// type Props = {
//     value: number
//     change: number
//     text: string
//     className?: string
// }

export const KpiRibbon = ({value, change, progress, text, className}) => (
  <SimpleCard className={className}>
    <div className='d-flex align-items-center mt-5 mb-2' style={{minWidth:'200px'}}>
      <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>
      <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{formatNumber(value)}</span>
      {change &&
      <span className={`badge badge-light-${change >=0 ? 'success' : 'danger'} fs-base text-${change >=0 ? 'success' : 'danger'}`}>
        {change >= 0 ?
        <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-5 svg-icon-success ms-n1 pe-1'/> :
        <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-5 svg-icon-danger ms-n1 pe-1'/>}
        {formatNumber(change*100, 2)}%
      </span>}
    </div>
    <span className='text-gray-400 fw-semibold fs-4'>{text}</span>
    {progress &&
    <>
      <div className='d-flex justify-content-end fw-bold fs-6 text-black opacity-75 w-100 mt-auto mb-2'>
        <span>72%</span>
      </div>
      <div className='h-8px w-100 bg-black bg-opacity-50 rounded'>
        <div className='bg-black rounded h-8px' role='progressbar' style={{width: '72%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
      </div>
    </>}
  </SimpleCard>
)

export const KpiChart = ({data, height, className}) => {
  const [chart, setChart] = useState({options: {}, series: []});

  useEffect(() => {
    if (!data) { return; }
    setChart({
      options: {
        stroke: { curve: 'smooth'},
        yaxis: {
          labels: { formatter: v => formatNumber(v) }
        },
        xaxis: {
          labels: { formatter: v => moment(v).format('DD MMM') },
          categories: data.map(d => d.date),
        },
        tooltip: {
          shared: true,
          x: { formatter: i => moment(data[i-1].date).format('DD/MM/YYYY') },
        }
      },
      series: [{
        name: "Revenue",
        type: 'area',
        data: data.map(d => d.revenue)
      },{
        name: "Profit",
        type: 'area',
        data: data.map(d => d.profit)
      },{
        name: "Revenue Run Rate",
        type: 'line',
        data: data.map(d => d.rr)
      }]
    });
  }, [data])

  return chart && (
      <SimpleCard className={className}>
          <Chart options={chart.options} series={chart.series} height={height} />
      </SimpleCard>
  )
}