import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

const styles = {
  control: (base:any) => ({...base,
    minHeight: '34px',
  }),
  dropdownIndicator: (base:any) => ({...base,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  }),
  clearIndicator: (base:any) => ({...base,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
  }),
  // input: (base:any) => ({...base,
  //   paddingTop: 0,
  //   paddingBottom: 0,
  //   marginTop: 0,
  //   marginBottom: 0,
  // }),
  // valueContainer: (base:any) => ({...base,
  //   // height: '30px',
  //   paddingTop: 0,
  //   paddingBottom: 0,
  //   marginTop: 0,
  //   marginBottom: 0,
  // }),
  // indicatorContainer: (base:any) => ({...base,
  //   height: '30px'
  // })
}

export const SingleSelect = (props:any) => (
  <Select {...props} 
    className="basic-single w-100"
    classNamePrefix="select"
    styles={styles}
    menuPortalTarget={document.body}
  />
)

export const MultiSelect = (props:any) => props.creatable ? 
  <CreatableSelect {...props} 
    isMulti={true}
    className="basic-multi-select w-100"
    classNamePrefix="select"
    styles={styles}
    menuPortalTarget={document.body}
  /> : (
  <Select {...props} 
    isMulti={true}
    className="basic-multi-select w-100"
    classNamePrefix="select"
    styles={styles}
    menuPortalTarget={document.body}
  />
)

