import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit'
// import { State } from '../../core/types';
import { errorHandler } from '../../core/network';

type State = {
  views: any[];
  loading: boolean;
  error: string | null;
}

const initialState:State = {
    loading: false,
    error: null,
    views: [],
}

export const statSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
    },
    getViews: (state, action) => {
      state.loading = false;
      state.error = null;
      state.views = action.payload;
    },
    saveView: (state, action) => {
      state.loading = false;
      state.error = null;
      const vi = state.views.findIndex(v => v.id === action.payload.id);
      if (vi >= 0) {
        state.views[vi] = action.payload;
      }
      else {
        state.views.push(action.payload);
      }

    },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
})
  

export const getViews = () => async (dispatch:any) => {
  try{
    dispatch(statSlice.actions.fetch());
    const res = await axios.get('stat/report_views');
    dispatch(statSlice.actions.getViews(res.data));
  }
  catch (error:any) {
    dispatch(statSlice.actions.failure(errorHandler(error)));
  }  

}

export const saveView = (view:any) => async (dispatch:any) => {
  try{
    dispatch(statSlice.actions.fetch());
    const res = await axios.post('stat/save_report', view);
    dispatch(statSlice.actions.saveView(res.data));
  }
  catch (error:any) {
    dispatch(statSlice.actions.failure(errorHandler(error)));
  }  
}

export default statSlice.reducer