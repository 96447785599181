import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import statReducer from './components/statistics/statRedux';
import advReducer from './components/advertisers/advRedux';
import appReducer from './components/application/appRedux';
import toolbarReducer from './components/toolbar/toolbarRedux';
import pubReducer from './components/common/pubRedux';
import userReducer from './components/common/userRedux';
import searchReducer from './components/search/searchRedux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';


const rootReducer = combineReducers({
  statistics: statReducer,
  toolbar: toolbarReducer,
  applications: appReducer,
  advertisers: advReducer,
  publishers: pubReducer,
  search: searchReducer,
  users: userReducer,
});

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({serializableCheck: false})
});

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch