import moment from "moment"
import { Moment } from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select, { components, OptionProps, SingleValueProps } from "react-select";
import { RootState } from "../../store"
import { setApplication, setDateRange } from "./toolbarRedux"
import { ApplicationType } from "../application/appRedux";
import { ApplicationItem } from "../common";

type periodProps = {
  name: string,
  startDate: Moment,
  endDate: Moment,
}

let periods:periodProps[] = []
for (let i = 0; i <= 12; i++) {
  let month = moment().add(-1, 'year').add(i, 'month')
  periods.push({
    name: month.format('MMM-YY'),
    startDate: month.startOf('month'),
    endDate: moment(month).endOf('month')
  });
}

type ApplicationOption = {
  name: string,
  logo: string,
}


const Option = (props:OptionProps<ApplicationOption>) => (
    <components.Option {...props}>
      <ApplicationItem name={props.data.name} logo={props.data.logo} />
    </components.Option>
)

const SingleValue = ({children, ...props}: SingleValueProps<ApplicationOption>) => {
  return (
  <components.SingleValue {...props}>
    <ApplicationItem name={props.data.name} logo={props.data.logo} />
  </components.SingleValue>
)};

export const ToolbarDashboard = () => {
    const { dateRange, appId } = useSelector((s:RootState) => s.toolbar);
    const { data } = useSelector(s => s.applications);
    const dispatch = useDispatch();
  
    useEffect(() => {
      document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    }, [])
  
    const handlePeriod = (startDate: Moment, endDate: Moment, label:string) => {
      dispatch(setDateRange({
        startDate:startDate.format('YYYY-MM-DD'), 
        endDate: endDate.format('YYYY-MM-DD'), 
        label
      }));
    }

    const handleSelect = (v:ApplicationType) => {
      dispatch(setApplication(v ? v.id : null));
    }

    return (
      <div className='d-flex align-items-center justify-content-between w-100'>
        {data &&
        <div className="py-2" style={{width: '300px'}}>
          <Select
            className="basic-single w-100"
            options={data}
            components={{Option, SingleValue}}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            isClearable={true}
            isSearchable={true}
            onChange={handleSelect}
            value={data?.find(d => d.id === appId)}
          />
        </div>}
        <div className='d-flex align-items-center flex-shrink-0'>        
          {/* <div className='bullet bg-secondary h-35px w-1px mx-5'></div> */}
          <div className='flex-shrink-0 '>
            <ul className='nav'>
              {periods.map(p => (
              <li key={p.name} className='nav-item'>
              <button 
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ' + (dateRange.label === p.name ? 'active' : '')}
                data-bs-toggle='tab'
                onClick={() => handlePeriod(p.startDate, p.endDate, p.name)}
              >{p.name}</button>
            </li>))}
            </ul>
          </div>
        </div>
      </div>
    )
  }