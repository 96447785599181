import _ from 'lodash';
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from 'react-bootstrap';
import { setToolbarType } from "../components/toolbar/toolbarRedux";
import { RootState } from "../store";
import { usePageData } from '../_metronic/layout/core'
import { 
    ApplicationList, 
    PublisherList, 
    TopCountries, 
    TopPublishers, 
    TopAdvertisers, 
    KpiRibbon, 
    KpiChart } from '../components/dashboard';
import { errorHandler } from "../core/network";
                  
export function DashboardPage() {
    const { setPageTitle, setPageBreadcrumbs } = usePageData()
    const {type, dateRange, appId} = useSelector((s:RootState) => s.toolbar);
    const [kpis, setKpis] = useState({});
    const [state, setState] = useState({loading: false, error: null});
    const {startDate, endDate} = dateRange;

    const dispatch = useDispatch()

    useEffect(() => {
        setPageTitle('Dashboard');
        setPageBreadcrumbs([]);
    }, [setPageTitle, setPageBreadcrumbs]);
    
    useEffect(() => {
        if (type !== 'dashboard') {
            dispatch(setToolbarType('dashboard'));
        }
    }, [dispatch, type])

    useEffect(() => {
        setState(s => ({...s, loading: true}))
        const params = {
            'startDate': startDate,
            'endDate': endDate,
            'application': appId,
        }
        axios.get('/stat/daily', {params})
        .then(({data}) => {
            const revenue = data.map(d => d.revenue);
            const profit = data.map(d => d.profit);
            let kpis = {
                data,
                revenue: {
                    value: _.sum(revenue),
                    progress: 0,
                },
                profit: {
                    value: _.sum(profit),
                    progress: 0,
                },
                rr: {
                    value: data.length > 1 ? data[data.length-2].rr : data[0].rr,
                    cmp: data.length > 2 ? data[data.length-3].rr : null,
                    progress: 0,
                }
            }
            kpis.revenue['cmp'] = kpis.revenue.value - revenue[revenue.length-1]
            kpis.profit['cmp'] = kpis.profit.value - revenue[profit.length-1]
            setKpis(kpis)
            setState({loading: false, error: null})
        })
        .catch(error => {
            setState({loading: false, error: errorHandler(error)})
        });

    }, [startDate, endDate, appId])

    const {revenue, profit, rr, data} = kpis;
    return (
        <>
            <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
            <div className="d-flex justify-content-between">
                <KpiRibbon value={revenue?.value} change={revenue?.cmp > 0 ? revenue.value/revenue.cmp - 1 : 0} text="Total Revenue" />
                <KpiRibbon value={profit?.value} change={profit?.cmp > 0 ? profit.value/profit.cmp - 1 : 0} text="Total Profit" />
                <KpiRibbon value={rr?.value} change={rr?.cmp > 0 ? rr.value/rr.cmp - 1 : 0} text="Daily Run Rate" />
                {/* <RibbonWidget className="" value={69700} change={0.022} text="Revenue Progress" /> */}
            </div>
            <KpiChart data={data} height={400} className="mt-5" />
            {!!appId ?
            <PublisherList className="mt-5" startDate={startDate} endDate={endDate} application={appId} /> :
            <>
            <div className="row mt-5">
                <div className='col-4'>
                    <TopPublishers startDate={startDate} endDate={endDate} application={appId} />
                </div>
                <div className='col-4'>
                    <TopAdvertisers startDate={startDate} endDate={endDate} application={appId} />
                </div>
                <div className='col-4'>
                    <TopCountries startDate={startDate} endDate={endDate} application={appId} />
                </div>
            </div>
            <ApplicationList className="mt-5" startDate={startDate} endDate={endDate} />
            </>}
        </>
    )
}
