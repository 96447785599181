import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from '../store'
import { PivotTable } from "../components/PivotTable";
import { usePageData } from "../_metronic/layout/core";
import { Alert, Spinner } from "react-bootstrap";
import { setToolbarType } from "../components/toolbar/toolbarRedux";
import { defaultCol, eventLabel } from "../core/helpers";
import { errorHandler } from "../core/network";
import { State } from "../core/types";


const columns = [
    {field: 'application', filter: true, headerName: 'Application', enableRowGroup: true, enablePivot: true},
    {field: 'os', filter: true, headerName: 'OS', enableRowGroup: true, enablePivot: true, cellRenderer: ({value}: {value:string}) => <img src={`/media/custom/${value}.png`} alt={value} height='25px'/>},
    {field: 'mmp', filter: true, headerName: 'MMP', enableRowGroup: true, enablePivot: true, cellRenderer: ({value}: {value:string}) => <img src={`/media/custom/${value}-icon.png`} alt={value} height='25px'/>},
    {field: 'country', filter: true, enableRowGroup: true, enablePivot: true},
    {field: 'publisher', filter: true, headerName: 'Publisher', enableRowGroup: true, enablePivot: true},
    {field: 'campaign', filter: true, headerName: 'Campaign', enableRowGroup: true, enablePivot: true},
    {field: 'conversion_type', filter: true, headerName: 'Conversion Type', enableRowGroup: true, enablePivot: true},
    {field: 'event', filter: true, enableRowGroup: true, enablePivot: true, valueFormatter: ({value}: {value:string}) => eventLabel(value)},
    {...defaultCol, field: 'impressions'},
    {...defaultCol, field: 'clicks'},
    {...defaultCol, field: 'conversions'},
    {...defaultCol, field: 'occurrences', headerName: 'Payable Events'},
    {...defaultCol, field: 'revenue'},
    {...defaultCol, field: 'cost'},
    {...defaultCol, field: 'profit'},
]


export function StatisticsPage() {
    const { dateRange, type } = useSelector((s:RootState) => s.toolbar)
    const [state, setState] = useState<State>({loading: false, error: null, data: []});
    const { setPageTitle, setPageBreadcrumbs } = usePageData()
    const dispatch:AppDispatch = useDispatch()

    useEffect(() => {
        setPageTitle('Statistics');
        setPageBreadcrumbs([]);
    }, [setPageTitle, setPageBreadcrumbs]);

    useEffect(() => {
        if (type !== 'statistics') {
            dispatch(setToolbarType('statistics'));
        }
    }, [dispatch, type])


    useEffect(() => {
        const {startDate, endDate} = dateRange;
        setState(s => ({...s, loading: true}));
        axios.get('stat/', {params: {startDate, endDate}})
        .then(({data}) => {
            setState(() => ({loading: false, error: null, data}));
        })
        .catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
    }, [dateRange]);
    
    return (
        <>
            {state.loading && 
            <div className="d-flex fs-5">
                <Spinner animation="border" className="me-2"/>
                <div>Please wait...</div>
            </div>}
            <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
            <PivotTable data={state.data} columns={columns} />
        </>)
        
}