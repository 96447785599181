import axios from 'axios';
import { useState, useEffect } from "react";
import { SimpleCard } from '../card';
import { formatNumber } from '../../core/helpers';
import { errorHandler } from '../../core/network';

const TOP = 10

export const TopPublishers = ({startDate, endDate, application, className}) => {
  const [publishers, setPublishers] = useState();
  const [state, setState] = useState({loading: false, error: null});

  useEffect(() => {
    setState(s => ({...s, loading: true}));
    axios.get('stat/publishers', {params: {startDate, endDate, application}})
    .then(({data}) => {
      setPublishers(data)
      setState({loading: false, error: null});
    })
    .catch(error => {
      setState({loading: false, error: errorHandler(error)});
    });
  }, [startDate, endDate, application])

  return (
    <SimpleCard title={`Top ${TOP} Publishers`} loading={state.loading} error={state.error} className={className} >
      <div className="table-responsive">
        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
          <thead>
            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">                                    
              <th className="p-0 pb-3 min-w-175px text-start">PUBLISHER</th>
              <th className="p-0 pb-3 min-w-100px text-end">REVENUE</th>
              <th className="p-0 pb-3 min-w-100px text-end">PROFIT</th>
              <th className="p-0 pb-3 min-w-100px text-end">MARGIN</th>
              <th className="p-0 pb-3 min-w-100px text-end">SHARE</th>
            </tr>
          </thead>
          <tbody>
            {publishers && publishers.map((p, i) => i < TOP && (
            <tr key={p.id}>
              <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{p.name}</td>
              <td className="text-end pe-0 text-gray-600 fw-bold fs-6">{formatNumber(p.revenue)}</td>
              <td className="text-end pe-0 text-gray-600 fw-bold fs-6">{formatNumber(p.profit)}</td>
              <td className="text-end pe-0 text-gray-600 fw-bold fs-6">{formatNumber(p.margin * 100)}%</td>
              <td className="text-end pe-0 text-gray-600 fw-bold fs-6">{formatNumber(p.share * 100, 2)}%</td>
            </tr>))}
          </tbody>
        </table>
      </div>
    </SimpleCard>
  )
}