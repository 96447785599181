/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ToolbarDateRange } from './ToolbarDaterRange';
import { ToolbarDashboard } from './ToolbarDashboard';
import { ToolbarAnalytics } from './ToolbarAnalytics';
import { ToolbarSearch } from './ToolbarSearch';


export const MyToolbar = () => {
  const { type } = useSelector((s:RootState) => s.toolbar);
  switch (type) {
    case 'dashboard':
      return <ToolbarDashboard />;
    case 'statistics':
      return <ToolbarDateRange />;
    case 'analytics':
      return <ToolbarAnalytics />
    case 'search':
      return <ToolbarSearch />
    default:
      return <></>
  }
}



