import moment from 'moment'
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { setToolbarType } from "../components/toolbar/toolbarRedux";
import { usePageData } from "../_metronic/layout/core";
import { Link, useParams } from "react-router-dom";
import { SimpleCard } from "../components/card";
import { Alert, Spinner } from "react-bootstrap";
import axios from "axios";
import { errorHandler } from "../core/network";
import _ from "lodash";
import { formatNumber } from "../core/helpers";

export const SearchSettingsPage:FC = () => {
  const { type } = useSelector((s:RootState) => s.toolbar);
  const {loading, data, error} = useSelector((s:RootState) => s.search);
  const { setPageTitle, setPageBreadcrumbs } = usePageData()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setPageTitle('Settings - Search');
    setPageBreadcrumbs([]);
  }, [setPageTitle, setPageBreadcrumbs]);

  useEffect(() => {
    if (!!type) {
      dispatch(setToolbarType(null));
    }
  }, [dispatch, type])

  return <>
    <Alert variant="danger" show={!!error}>{error}</Alert>
    <div className="d-flex justify-content-between mb-3">
      <div>
        {loading && 
        <div className="d-flex fs-5">
          <Spinner animation="border" className="me-2"/>
          <div>Loading...</div>
        </div>}
      </div>
    </div>

    <div className="row">
      {data.map(d => (
      <div key={d.id} className="col-4 mb-4">
        <Link to={`/settings/search/${d.id}`} style={{ textDecoration: 'none' }}>
          <SimpleCard>
            <div className='d-flex justify-content-start mt-5'>
              <img src={d.logo} alt={d.code} height='50px'/>
              <div className='d-flex justify-content-center flex-column ms-3'>
                <span className='text-dark fw-bold text-hover-primary'>{d.name}</span>
                <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
              </div>
            </div>
          </SimpleCard>
        </Link>
      </div>))}
    </div>
  </>
}


export const SearchProviderPage:FC = () => {
  const { id } = useParams()
  const { type } = useSelector((s:RootState) => s.toolbar);
  const {data} = useSelector((s:RootState) => s.search);
  const { setPageTitle, setPageBreadcrumbs } = usePageData();
  const dispatch:AppDispatch = useDispatch()
  const [provider, setProvider] = useState<any>();

  useEffect(() => {
    if (id) {
      const p = data && data.find(p => p.id === parseInt(id))
      if (!_.isEqual(provider, p)) { 
        setProvider(p);
      }
    }
  }, [data, id, provider]);

  
  useEffect(() => {
    if (!!type) {
      dispatch(setToolbarType(null));
    }
  }, [dispatch, type])

  useEffect(() => {
    if (provider) {
      setPageTitle(provider.name);
      setPageBreadcrumbs([{title: 'Search', path: '/settings/search', isSeparator: false, isActive: true}]);
    }
  }, [provider, setPageTitle, setPageBreadcrumbs])

  return provider ? <ProviderDetails provider={provider} /> : <></>
}  

const ProviderDetails = ({provider}) => {
  const [state, setState] = useState({loading: false, error:null, data: []});

  useEffect(() => {
    setState(s => ({...s, loading:true}));
    axios.get(`/search/feeds/${provider.id}`)
    .then(({data}) => setState({loading: false, error: null, data}))
    .catch(error => setState(s => ({...s, loading: false, error: errorHandler(error)})));
  }, [provider]);

  const handleSync = useCallback(() => {
    setState(s => ({...s, loading: true}));
    const params = {id: provider.id}
    axios.get('/search/sync', {params})
    .then(() => setState(s => ({...s, loading: false, error: null})))
    .catch(error => setState(s => ({...s, loading: false, error: errorHandler(error)})));
  }, [provider])

  const Toolbar = () =>  
    <div className="d-flex">
      <button disabled={state.loading} className='btn btn-sm btn-light-primary fw-bold align-self-center' onClick={handleSync}>
        {state.loading && <Spinner animation="border" className="me-2 spinner-border-sm"/>}
        <span></span>Sync
      </button>
    </div>

  return (
    <SimpleCard title={<Title provider={provider} />} error={state.error} toolbar={<Toolbar />}>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th>Feed</th>
              <th>Supply</th>
              <th>Commission</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.data.map(d => (
            <tr key={d.feed}>
              <td>{d.feed}</td>
              <td>{d.supply.name}</td>
              <td>{formatNumber(d.commission * 100)}%</td>
              <td></td>
            </tr>))}
          </tbody>
        </table>
      </div>
    </SimpleCard>
  )
}

const Title = ({provider}) => (
  <div className="d-flex align-items-center">
    <img className="me-3 mt-3" src={provider.logo} alt="logo" height="100" />
    <div>
      <h1>{provider.name}</h1>
      <div className='mt-5 text-muted fs-6'>Account Manager: {provider.am_id || 'N/A'}</div>
      <div className='mt-5 text-muted fs-6'>Last sync: {provider.sync ? moment.utc(provider.sync).local().format('DD/MM/YYYY HH:MM') : 'N/A'}</div>
    </div>
  </div>
)

