import moment from "moment"
import { Moment } from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { DateRangePicker } from "../DateRangePicker"
import { setDateRange } from "./toolbarRedux"


export const ToolbarDateRange = () => {
    const {startDate, endDate, label} = useSelector((s:RootState) => s.toolbar.dateRange)
    const dispatch = useDispatch()
  
    useEffect(() => {
      document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    }, [])
  
    const handlePeriod = (startDate: Moment, endDate: Moment, label:string) => {
      dispatch(setDateRange({
        startDate:startDate.format('YYYY-MM-DD'), 
        endDate: endDate.format('YYYY-MM-DD'), 
        label
      }));
    }
  
    return (
      <div className='d-flex align-items-center overflow-auto'>
        <div className='d-flex align-items-center flex-shrink-0'>        
          {/* <div className='bullet bg-secondary h-35px w-1px mx-5'></div> */}
          <div className='flex-shrink-0 '>
            <ul className='nav'>
              <li className='nav-item'>
                <button 
                  className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ' + (label === 'MTD' ? 'active' : '')}
                  data-bs-toggle='tab'
                  onClick={() => handlePeriod(moment().startOf('month'), moment(), 'MTD')}
                >This month</button>
              </li>
  
              <li className='nav-item'>
                <button
                  className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ' + (label === 'LM' ? 'active' : '')}
                  data-bs-toggle='tab'
                  onClick={() => handlePeriod(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'), 'LM')}
                >Last month</button>
              </li>
  
              <li className='nav-item'>
                <button
                  className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ' + (label === 'YTD' ? 'active' : '')}
                  data-bs-toggle='tab'
                  onClick={() => handlePeriod(moment().startOf('year'), moment(), 'YTD')}
                >This year</button>
              </li>
            </ul>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <DateRangePicker 
            className="form-control form-control-sm"
            startDate={moment(startDate)} 
            endDate={moment(endDate)}
            onCallback={handlePeriod}
          />
        </div>
      </div>
    )
  }