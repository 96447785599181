import axios from "axios";
import { useEffect, useRef, useState, FC, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, CellClickedEvent } from "ag-grid-community";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faChevronRight, faExclamationTriangle, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setToolbarType } from "../components/toolbar/toolbarRedux";
import { RootState } from "../store";
import { usePageData } from '../_metronic/layout/core'
import { errorHandler } from "../core/network";
import { useSearchParams } from 'react-router-dom';
import { defaultCol, formatNumber } from '../core/helpers';
import { State } from '../core/types';
import { ApplicationItem } from "../components/common";
import { FraudReason, FraudTrend } from "../components/optimization";
import { SimpleCard } from "../components/card";

library.add(fas, faExclamationTriangle, faCheck, faChevronRight);

const ApplicationColumn:FC<{id:number}> = ({id}) => {
  const apps = useSelector((s:RootState) => s.applications);
  const app = useMemo(() => apps.data && apps.data.find(a => a.id === id), [id, apps.data]);
  return app ? <ApplicationItem className='' name={app.name} logo={app.logo} logoSize={25} /> : <></>
}

const dimensions:ColDef[] = [
  {
    field: 'application', 
    pinned: 'left',
    width: 200,
    cellRenderer: ({data}: {data:{application_id: number}}) => <ApplicationColumn id={data.application_id} />,
  },{
    field: 'os', 
    pinned: 'left',
    headerName: 'OS',
    width: 200,
    cellRenderer: ({value}: {value:string}) => <img src={`/media/custom/${value}.png`} alt={value} height='25px' />
  },
  // {field: 'code', pinned: 'left'},
  {field: 'publisher', pinned: 'left', width: 200},
  {field: 'publisher_code', headerName: 'PID', pinned: 'left', width: 200},
  {field: 'site_id', headerName: 'SiteID', pinned: 'left', width: 200},
]
const optionalMetrics:ColDef[] = [
  {...defaultCol, field: 'impressions', width: 120},
  {...defaultCol, field: 'clicks', width: 120}
]

const metrics:ColDef[] = [
  {...defaultCol, field: 'conversions', headerName: 'Installs/RT', width: 120},
  {...defaultCol, 
    headerName: 'CR%', 
    width: 100, 
    valueGetter: ({data}) => data.conversions / data.clicks * 50, 
    valueFormatter: ({value}) => formatNumber(value, 2) + '%'
  },  
  {...defaultCol, field: 'pa', headerName: 'PA', width: 100},
  {...defaultCol, headerName: 'PA%', width: 100, 
    valueGetter: ({data}) => data.pa / data.conversions * 100, 
    valueFormatter: ({value}) => formatNumber(value, 1) + '%'
  },
  {...defaultCol, field: 'rt', headerName: 'Blocked', width: 100},
  // {...defaultCol, headerName: 'RT%', width: 70, 
  //   valueGetter: ({data}) => data.rt / (data.pa + data.rt)*100, 
  //   valueFormatter: ({value}) => formatNumber(value, 1) + '%'
  // },
  {...defaultCol, headerName: 'Fraud', width: 100, valueGetter: ({data}) => data.pa + data.rt},
  {...defaultCol, headerName: 'Fraud%', width: 100, 
    valueGetter: ({data}) => (data.pa + data.rt) / (data.conversions + data.rt) * 100, 
    valueFormatter: ({value}) => formatNumber(value, 1) + '%'
  },
  // {...defaultCol, headerName: 'Bots', width: 100, valueGetter: ({data}) => data.pa_bots + data.rt_bots},
  // {...defaultCol, headerName: 'Bots%', width: 90, 
  //   valueGetter: ({data}) => (data.pa_bots + data.rt_bots) / (data.pa + data.rt)*100, 
  //   valueFormatter: ({value}) => formatNumber(value, 1) + '%'
  // },
  // {...defaultCol, headerName: 'CF', width: 100, valueGetter: ({data}) => data.pa_cf + data.rt_cf},
  // {...defaultCol, headerName: 'CF%', width: 90, 
  //   valueGetter: ({data}) => (data.pa_cf + data.rt_cf) / (data.pa + data.rt)*100, 
  //   valueFormatter: ({value}) => formatNumber(value, 1) + '%'
  // },
  // {...defaultCol, headerName: 'BA', width: 100, valueGetter: ({data}) => data.pa_ba + data.rt_ba},
  // {...defaultCol, headerName: 'BA%', width: 90, 
  //   valueGetter: ({data}) => (data.pa_ba + data.rt_ba) / (data.pa + data.rt)*100, 
  //   valueFormatter: ({value}) => formatNumber(value, 1) + '%'
  // },
]

const warnCol:ColDef = {
  field: 'warning',
  headerName: '',
  pinned: 'left',
  width: 10,
  cellRenderer: ({value}) => 
    <div>
      {!!value ?
      <span className="fa-4"><FontAwesomeIcon icon={faExclamationTriangle} /></span>:
      <FontAwesomeIcon icon={faCheck} />}
    </div>
}

const filters = ['application', 'os', 'publisher', 'publisher_code', 'siteId']
export function OptimizationPage() {
    const { setPageTitle, setPageBreadcrumbs } = usePageData()
    const {type} = useSelector((s:RootState) => s.toolbar);
    const gridRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const [reqParams, setReqParams] = useState<any>();
    const [state, setState] = useState<State>({loading: false, error: null, data: []});
    const [cols, setCols] = useState<ColDef[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
      setPageTitle('Optimization');
      setPageBreadcrumbs([]);
    }, [setPageTitle, setPageBreadcrumbs]);
    
    useEffect(() => {
      if (type !== 'analytics') {
        dispatch(setToolbarType('analytics'));
      }
    }, [dispatch, type]);
    
    useEffect(() => {
      if (!searchParams.get('start') || !searchParams.get('end')) { return; }
      else if (!searchParams.get('application')) {
        setSearchParams({
          start: searchParams.get('start'),
          end: searchParams.get('end'),
        });
      }
      let params:any = {}
      searchParams.forEach((value, key) => params[key] = value);
      setReqParams(params);
    }, [searchParams, setSearchParams]);

    useEffect(() => {
      if (!reqParams) { return; }
      setState(s => ({...s, loading: true}));
      axios.get('/optimization/data', {params: reqParams})
      .then(({data}) => {
        setState({loading: false, error: null, data});
        let newCols:ColDef[] = [] 
        if (data.length > 0) { 
          newCols.push(warnCol);
          for (let i in dimensions) {
            const d = dimensions[i];
            if (!d.field) { return; }
            const hide = reqParams.hasOwnProperty(d.field);
            newCols.push({...d, hide});
            if (!hide) { break; }
          }
          if (!newCols.find(c => c.field === 'site_id')) {
            optionalMetrics.forEach(m => newCols.push({...m}));
            metrics[1].hide = false;
          }
          else {
            metrics[1].hide = true
          }
        }
        setCols([...newCols, ...metrics]);
      })
      .catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
    }, [reqParams, setSearchParams]);

    const handleCellClicked = (e:CellClickedEvent) => {
      switch(e.colDef.field) {
        case 'application':
          searchParams.append('application', e.data.application_id);
          break;
        case 'os':
          searchParams.append('os', e.data.os);
          break;
        case 'publisher':
          searchParams.append('publisher', e.data.publisher_id);
          break;
        case 'publisher_code':
          searchParams.append('publisher_code', e.data.publisher_code);
          break;
        default:
          return;
      }
      setSearchParams(searchParams);
    }

    const handleBreadCrumbsClick = useCallback((f:string) => {
      if (f === 'home') {
        const start = searchParams.get('start');
        const end = searchParams.get('end');
        if (start && end) {
          setSearchParams({start, end});
        }
        else {
          console.error("missing start/end url search parameters");
        }
      }
      else {
        const index = filters.indexOf(f)
        for (let i=index+1; i < filters.length; i++) {
          if (searchParams.get(filters[i])) {
            searchParams.delete(filters[i]);
          }
        }
        setSearchParams(searchParams);
      }
    }, [searchParams, setSearchParams]);

    const handleGridReady = () => state.loading && gridRef.current?.api.showLoadingOverlay();
    const {error, data} = state;
    return data && data.length > 0 && (
        <>
          <div className="row mb-5">
            <div className="col-3">
              <SimpleCard>
                <FraudReason params={reqParams} />
              </SimpleCard>
            </div>
            {reqParams.application &&
            <div className="col-9">
              <SimpleCard>
                <FraudTrend params={reqParams} />
              </SimpleCard>
            </div>}
          </div>
          <div className={`ag-theme-alpine`} style={{width: 'auto', height:'500px'}}>
          <Alert variant="danger" show={!!error}>{error}</Alert>
          {data && data.length > 0 &&
          <div className="d-flex mb-3">
            {searchParams.get('application') &&
            <button className="btn btn-link fs-5 py-0" onClick={() => handleBreadCrumbsClick('home')}>Home</button>}
            {filters.map((f, i) => !!searchParams.get(f) && !!data[0][f] &&
            <div key={i} className="d-flex align-items-center">
              <FontAwesomeIcon className="mx-2 text-muted" icon={faChevronRight} />
              <button className="btn btn-link fs-5 py-0" 
                disabled={!searchParams.get(filters[i+1])}
                onClick={() => handleBreadCrumbsClick(f)}>{data[0][f]}</button>
            </div>)}
          </div>}
          <AgGridReact
              ref={gridRef}
              rowData={data} 
              columnDefs={cols}
              onCellClicked={handleCellClicked}
              // rowHeight={70} 
              // suppressContextMenu={true}
              // onFirstDataRendered={e => e.columnApi.autoSizeAllColumns()}
              overlayLoadingTemplate={'<span class="ag-overlay-loading-center fs-1 mx-5 my-5">Loading...</span>'}
              onGridReady={handleGridReady}
          />
        </div>
      </>
    )
}
