import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../../store';
import { errorHandler } from '../../core/network';


const initialState:{loading:boolean, error:any, data: any[]} = {
  loading: false,
  error: null,
  data: []
}

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
    },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    success: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },
  }
});

export const getUsers = () => async (dispatch:AppDispatch) => {
  try {
    dispatch(userSlice.actions.fetch());
    const res = await axios.get('/auth/users')
    dispatch(userSlice.actions.success(res.data))
  }
  catch (error:any) {
    dispatch(userSlice.actions.failure(errorHandler(error)));
  }
}

export default userSlice.reducer