/* eslint-disable jsx-a11y/anchor-is-valid */
import moment, { Moment } from 'moment';
import React, { FC, forwardRef } from 'react';
import {useEffect, useRef} from 'react'
import {DateRangePicker as DRP} from 'react-bootstrap-daterangepicker'
import DP from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap-daterangepicker/daterangepicker.css';

interface CallbackHandler {
  (start: Moment, end: Moment): void;
}

export interface DateRangeProps {
  startDate: Moment;
  endDate: Moment;
  onCallback: CallbackHandler;
  className?: string;
  isDisabled?: boolean;
}

export const DateRangePicker: FC<React.PropsWithRef<DateRangeProps>> = ({startDate, endDate, onCallback, className, isDisabled}) => {
  const ref = useRef<DRP>();

  useEffect(() => {
    if (ref.current) {
      ref.current.setStartDate(startDate);
      ref.current.setEndDate(endDate);
    }
  }, [startDate, endDate])

  // const handleCallback = (startDate: Moment, endDate: Moment) => {
    // if (moment().startOf('month').diff(startDate, 'days') === 0 && endDate > moment()) {
    //   return onCallback(startDate, endDate, "MTD");
    // }
    // if (moment().subtract(1, 'month').startOf('month').diff(startDate, 'days') === 0 && moment().subtract(1, 'month').endOf('month').diff(endDate, 'days') === 0) {
    //   return onCallback(startDate, endDate, "LM");
    // }
    // if (moment().startOf('year').diff(startDate, 'days') === 0 && endDate > moment()) {
    //   return onCallback(startDate, endDate, "YTD");
    // }
    // return onCallback(startDate, endDate, '');
  // }

  return (
    <DRP ref={ref} onCallback={onCallback} initialSettings={{startDate, endDate}}>
      <button disabled={!!isDisabled} type="button" className={className || ""}>
        {startDate.format('DD/MM/YYYY')} - {endDate.format('DD/MM/YYYY')}
      </button>
    </DRP>
  )
}

export interface DatePickerProps {
  date: Moment;
  onChange: (date:Moment | null) => void;
  className?: string;
  clearable?: boolean;
  minDate?: Moment;
  disabled?: boolean;
}

interface CustomInputProps {
  value: Date;
  onClick: (e: any) => void;
  className?: string;
}

export const DatePicker: FC<React.PropsWithRef<DatePickerProps>> = ({date, onChange, className, clearable, minDate, disabled}) => {
  const handleClear = () => {
    onChange(null);
  }

  const CustomInput = forwardRef<HTMLElement, CustomInputProps>(({value, onClick, className, disabled}, ref) => (
    <div className="btn-group d-flex content-justify-between py-1 form-control" role='group'>
      <button className='btn btn-sm py-1' disabled={disabled} onClick={onClick} ref={ref}>{value || '...'}</button>
      {clearable && !!value &&
      <button className='btn btn-sm py-1' onClick={handleClear}>X</button>}
    </div>
  ));
  
  return <DP 
    className={className} 
    selected={!!date && date.valueOf()} 
    onChange={date => onChange(moment(date))} 
    customInput={<CustomInput />} 
    dateFormat="dd/MM/yyyy"
    minDate={!!minDate && minDate.valueOf()}
    disabled={disabled}
  />
}
