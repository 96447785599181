import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../../store";
import axios from "axios";
import { errorHandler } from "../../core/network";

type SearchProvider = { 
  id: number;
  name: string;
  code: string;
  logo: string;
  am_id: number;
}

type DefaultState = {
  loading: boolean;
  data: SearchProvider[] | null;
  error: string | null;
}

const initialState:DefaultState = {
  loading: false,
  data: null,
  error: null,
}


export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    fetch: (state) => { 
      state.loading = true; 
    },
    success: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const getProviders = () => async (dispatch:AppDispatch) => {
  try {
    dispatch(searchSlice.actions.fetch());
    const res = await axios.get('search/providers');
    dispatch(searchSlice.actions.success(res.data));
  }
  catch (error:any) {
    dispatch(searchSlice.actions.failure(errorHandler(error)));
  }
}



export default searchSlice.reducer