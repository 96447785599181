import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
// import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from './_metronic/layout/core'
import {MasterInit} from './_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'

const App = () => {
  useEffect(() => {
    window.process = {...window.process};
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* <I18nProvider> */}
        <LayoutProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
              </PersistGate>
          </Provider>
        </LayoutProvider>
      {/* </I18nProvider> */}
    </Suspense>
  )
}

export {App}
