import { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
// import ReactTags  from 'react-tag-autocomplete';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { LicenseManager } from 'ag-grid-enterprise';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { getViews, saveView } from './statistics/statRedux';
// import './react-tags.css'

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSE);


// const colum2tag = (column) => (
//     {id: column.field, name: column.headerName || column.field.charAt(0).toUpperCase() + column.field.slice(1)}
// )

export const PivotTable = ({data, columns}) => {
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const defaultColDef = useMemo(() => ({sortable: true, resizable: true}), []);
    const [gridState, setGridState] = useState();
    const [saveAsModal, setSaveAsModal] = useState(false);
    // const [colWidth, setColWidth] = useState()
    const {views} = useSelector(s => s.statistics);
    const dispatch = useDispatch()

    const handleExport = useCallback(() => {
        const gc = gridRef.current.columnApi.columnModel.rowGroupColumns.map(c => c.instanceId);
        gridRef.current.api.exportDataAsCsv({
            suppressQuotes: true,
            skipColumnGroupHeaders: false,
            shouldRowBeSkipped({node}) {
                return node.leafGroup === false;
            },
            processHeaderCallback({column, columnApi}) {
                if (column.colDef.headerName === 'Group') {
                    return columnApi.columnModel.rowGroupColumns.map(c => c.colDef.headerName || c.colDef.field).join(',')
                }
                return column.colDef.headerName || column.colDef.field;
            },
            processRowGroupCallback({node}) {
                const colIndex = gc.indexOf(node.rowGroupColumn.instanceId);
                const cols = []
                let parent = node.parent;
                while (parent.key) {
                    cols.splice(0, 0, parent.key);
                    parent = parent.parent;
                }
                cols.push(node.key);
                for (let i=0; i < gc.length - colIndex - 1; i++) { cols.push('Total')}
                return cols.join(',');}
        })
    }, []);


    const handleGroup = e => {
        if (e.columns.length > 0) {
            e.columnApi.setPivotMode(true)
        }
        else {
            e.columnApi.setPivotMode(false)
        }
        // e.columnApi.autoSizeAllColumns();
    }

    const getContextMenuItems = useCallback(({defaultItems}) => {
        return ['autoSizeAll', 'expandAll', 'contractAll', 'separator', 'copy', {
            name: 'Export',
            action: handleExport
        }];

    }, [handleExport])

    const handleSave = useCallback((name) => {
        const state = {
            columns: gridRef.current.columnApi.getColumnState(),
            filter: gridRef.current.api.getFilterModel(),
            pivot: gridRef.current.columnApi.isPivotMode(),
        }
        let view = views.find(v => v.name.toLowerCase() === name.toLowerCase());
        if (view) {
            view = {id: view.id, state, name}
        }
        else {
            view = {id: null, name, state}
        }
        setGridState(view);
        dispatch(saveView(view));
        setSaveAsModal(false);
    }, [dispatch, views]);

    
    const handleLoad = useCallback((view) => {
        const {state} = view;
        gridRef.current.columnApi.applyColumnState({state: state.columns, applyOrder: true});
        gridRef.current.api.setFilterModel(state.filter);
        gridRef.current.columnApi.setPivotMode(state.pivot);
        setGridState(view);
    }, []);

    // const handleResizeColumns = useCallback(e => {
    //     let resize = false
    //     const width = e.columnApi.columnModel.viewportColumns.map(c => c.actualWidth);
    //     if (colWidth && colWidth.length === width.length) {            
    //         for (let i=0; i < width.length; i++) {
    //             if (width[i] !== colWidth[i]) {
    //                 resize = true;
    //             }
    //         }
    //     }
    //     else {
    //         resize = true;
    //     }
    //     if (resize) {
    //         e.columnApi.autoSizeAllColumns();
    //         setColWidth(width);
    //         console.log('resized!')
    //     }
    // }, [colWidth]);
    
    useEffect(() => {
        dispatch(getViews());
    }, [dispatch])

    useEffect(() => {
        if (gridState && !gridState.id) {
            const v = views.find(v => v.name.toLowerCase() === gridState.name.toLowerCase());
            if (v) {
                setGridState({...gridState, id: v.id})
            }
        }
    }, [views, setGridState, gridState]);    

    return (
    <>
        <div className='row'>
            <div className='col-8'>
                <Select
                    options={views}
                    value={gridState}
                    getOptionLabel={o => o.name}
                    getOptionValue={o => o.id}
                    onChange={handleLoad}
                />
            </div>
            <div className='col-4 d-flex justify-content-end'>
                <button type="button" className="btn btn-primary mb-3 me-3" onClick={() => setSaveAsModal(true)}>Save</button>
            </div>
        </div>
        <div className="ag-theme-alpine" style={{width: '100%', height: 700}}>
            <AgGridReact
                ref={gridRef}
                rowData={data}
                columnDefs={columns}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowGroupPanelShow='always'
                sideBar={['columns', 'filters']}
                onColumnRowGroupChanged={handleGroup}
                suppressAggFuncInHeader={true}
                allowContextMenuWithControlKey={true}
                getContextMenuItems={getContextMenuItems}
                onFirstDataRendered={e => e.columnApi.autoSizeAllColumns()}
            />
        </div>
        <SaveAsModal show={saveAsModal} name={gridState?.name} onClose={() => setSaveAsModal(false)} onSubmit={(name) => handleSave(name)} />
    </>
    );
};

const SaveAsModal = ({onSubmit, show, onClose, name}) => {
    const [newName, setNewName] = useState();
    useEffect(() => {
        setNewName(name || '');
    }, [name]);

    return (
        <Modal id="saveAsModal" show={show} onHide={onClose} centered>
            <Modal.Body>
                <h3 className='d-flex justify-content-center mt-3 mb-5'>Save report template as</h3>
                <input type="text" className='form-control' value={newName} onChange={e => setNewName(e.target.value)} />
                <div className='d-flex justify-content-center my-3'>
                    <button className='btn btn-primary mx-3' disabled={!newName} onClick={() => onSubmit(newName)}>Save</button>
                    <button className='btn btn-default mx-3' onClick={onClose}>Cancel</button>
                </div>
            </Modal.Body>
        </Modal>)
}
  