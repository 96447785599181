// import { FC } from 
// type Props = {
//   start: str,
//   end:str,
//   application_id:number,
//   os: 'ios' | 'android',
//   publisher_id:number,
//   publisher_code:str
// }

import moment from "moment";
import axios from "axios";
import { useEffect, useState } from "react"
import Chart from "react-apexcharts";
import { errorHandler } from "../../core/network";
import { formatNumber } from "../../core/helpers";

// const FraudReason:FC<Props> = (params) => {
//   return <></>
// }

export const FraudTrend = ({params}) => {
  const [state, setState] = useState({loading: false, error: null, data: []});
  const [chart, setChart] = useState({options: {}, series: []});

  useEffect(() => {
    setState(s => ({...s, loading:true}));
    axios.get(`/optimization/${params.application}/trend`, {params})
    .then(({data}) => {
      setState({loading: false, error: null, data})
    }).catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
  }, [params]);

  useEffect(() => {
    const data = state.data;
    if (!data) { return; }
    setChart({
      options: {
        yaxis: {
          labels: { formatter: v => formatNumber(v) }
        },
        xaxis: {
          labels: { formatter: v => moment(v).format('DD MMM') },
          categories: data.map(d => d.date),
        },
      },
      series: [{
        name: "Post-attribution",
        type: 'area',
        data: data.map(d => d.pa)
      },{
        name: "Real-time",
        type: 'area',
        data: data.map(d => d.rt)
      }]
    });
  }, [state.data])

  return chart ?
    <Chart options={chart.options} series={chart.series} height={235} /> :
    <></>
}