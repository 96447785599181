import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit'

const initialState = 
{
    loading: false,
    data: null,
    error: null
}

export const advSlice = createSlice({
  name: 'advertisers',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    // update: (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   action.payload.forEach(adv => {
    //     const i = state.data.findIndex(a => a.id === adv.id);
    //     if (i >= 0) {
    //       state.data[i] = adv;
    //     }
    //     else {
    //       state.data.push(adv);
    //     }

    //   });
    // },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const getAdvertiser = (id) => async (dispatch) => {
  try{
    dispatch(advSlice.actions.fetch());
    const params = {id}
    const res = await axios.get('advertiser/', {params});
    if (!!id) {
      dispatch(advSlice.actions.update(res.data))
    }
    else {
      dispatch(advSlice.actions.success(res.data));
    }
    
  }
  catch (error) {
    dispatch(advSlice.actions.failure(error.message));
  }
}

export default advSlice.reducer