import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../../store';
import { errorHandler } from '../../core/network';

export type Publisher = {
  id: number,
  name: string
}

export type MissingCode = {
  id: number,
  pid: string,
  mmp: string,
  os: string,
  last_event: string,
  application: {
    id: number,
    name: string,
    code: string,
    logo: string,
  }
}

type PublisherState = {
  loading: boolean;
  error: string | null;
  data: Publisher[] | null;
  missingCodes: MissingCode[] | null;
}

const initialState:PublisherState = {
  loading: false,
  error: null,
  data: null,
  missingCodes: null,
}


export const pubSlice = createSlice({
  name: 'publisher',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
    },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    success: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.publishers;
      state.missingCodes = action.payload.missingCodes;
    },
  }
});

export const getPublishers = () => async (dispatch:AppDispatch) => {
  try {
    dispatch(pubSlice.actions.fetch());
    const res = await Promise.all([
      axios.get('/publisher/'),
      axios.get('/publisher/codes'),
    ]);
    dispatch(pubSlice.actions.success({publishers: res[0].data, missingCodes: res[1].data}));
  }
  catch (error:any) {
    dispatch(pubSlice.actions.failure(errorHandler(error)));
  }
}

export const setPublishers = (publishers: {pid:number, publisher:number}[]) => async (dispatch:AppDispatch) => {
  try {
    dispatch(pubSlice.actions.fetch());
    await axios.post('/publisher/', publishers);
    dispatch(getPublishers());
  }
  catch (error:any) {
    dispatch(pubSlice.actions.failure(errorHandler(error)));
  }
}

export default pubSlice.reducer