import { FC } from "react"

type Props = {
  name: string,
  logo?: string,
  logoSize?: number,
  className?: string,
  onClick?: React.MouseEventHandler<HTMLElement>,
  info?: string,
}

export const ApplicationItem:FC<Props> = ({name, logo, logoSize, className, onClick, info}) => (
  <div 
    style={{ textDecoration: 'none' }} 
    className={`d-flex justify-content-start ${className}`}
    onClick={!onClick ? () => false : onClick}
  >
    {logo &&
    <div className={`symbol symbol-${logoSize || 45}px me-3`}>
      <img src={logo} alt={name} />
    </div>}
    <div className='d-flex justify-content-center flex-column'>
      <span className='text-dark fw-bold text-hover-primary'>{name}</span>
      <span className='text-muted fw-semibold text-muted d-block fs-7'>{info}</span>
    </div>
  </div>
)