import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from 'react-redux';
import { Alert, Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useAuth } from '../../modules/auth';
import { SimpleCard } from "../card";
// import { KTSVG } from '../../_metronic/helpers';
import { errorHandler } from "../../core/network";
import { SingleSelect } from '../common/SelectWrapper';

const dsOptions = [
  {value: {mmp: 'appsflyer', account: 'gradientt'}, label: 'AppsFlyer'},
  {value: {mmp: 'appsflyer', account: 'mobcore'}, label: 'AppsFlyer (MC)'},
  {value: {mmp: 'appsflyer', account: 'borscht'}, label: 'AppsFlyer (BT)'},
  {value: {mmp: 'adjust', account: 'gradientt'}, label: 'Adjust'},
  {value: {mmp: 'adjust', account: 'sbermarket'}, label: 'Adjust (SM)'},
  {value: {mmp: 'adjust', account: 'artics'}, label: 'Adjust (AR)'},
  {value: {mmp: 'appmetrica', account: 'gradientt'}, label: 'AppMetrica'}
]

export const ApplicationDetails = ({app, onUpdate, className}) => {
  const [editApplication, setEditApplication] = useState(false);
  const [state, setState] = useState({loading: false, error: null, data: app});
  const [importModal, setImportModal] = useState({show: false, mmp: null, account:null});
  const [uploadModal, setUploadModal] = useState({show: false, source: null});
  const users = useSelector(s => s.users.data);
  const {currentUser} = useAuth();

  const newApp = useMemo(() => ({...state.data}), [state.data]);

  useEffect(() => {
    if (app) {
      setState(s => ({...s, data: {...app}}))
    }
  }, [app])
  
  const handleSubmit = useCallback(() => {
    setState(s => ({...s, loading: true}));
    axios.post('application/', newApp)
    .then(({data}) => {
      setState({loading: false, error: null, data});
      setEditApplication(false);
      onUpdate()
    })
    .catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
  }, [onUpdate, newApp]);

  const handleImportOptions = useCallback((index, mmp, account) => setImportModal({show: true, mmp, account, index}), [])

  const handleImportSubmit = useCallback((index, {code, os, timezone, icon, account}) => {
    setImportModal(false);
    if (!timezone) {
      timezone = newApp.timezone;
    }
    const logo = icon || newApp.logo;
    const sources = newApp.sources.map((s, i) => {
      if (i !== index) { return s; }
      return {...s, os, code, timezone, account}
    });
    setState(s => ({...s, data: {...newApp, timezone, logo, sources}}));
    setImportModal(im => ({...im, show: false}));
  }, [newApp]);

  const handleUpdateSource = useCallback((index, prop, value) => {
    const sources = newApp.sources.map((s, i) => {
      if (i !== index) { return s; }
      if (prop === 'mmp') {
        return {...s, ...value}
      }
      return {...s, [prop]: value}
    });
    setState(s => ({...s, data: {...newApp, sources}}));
  }, [newApp]);

  const handleAddSource = useCallback(() => {
    const source = {mmp: null, os: null, code: '', timezone: null, account: '', is_active: true}
    setState(s => ({...s, data: {...newApp, sources: [...newApp.sources, source]}}));
  }, [newApp])

  const allowAddSource = useCallback(() => {
    if (newApp.sources.length === 0) { return editApplication; }
    const ls = newApp.sources[newApp.sources.length-1];
    return editApplication && ls.code && ls.mmp;
  }, [newApp, editApplication]);

  const handleDiscard = useCallback(() => {
    setEditApplication(false);
    setState(s => ({...s, data: {...app}}));
  }, [app, setEditApplication]);

  const Toolbar = useCallback(() => editApplication ? (
      <div className="d-flex">
        <button className='btn btn-sm btn-light-primary fw-bold align-self-center me-3' onClick={handleSubmit}>Save</button>
        <button className='btn btn-sm btn-light-default fw-bold align-self-center' onClick={handleDiscard}>Discard</button>
      </div>) : (
      <div className="d-flex">
        <button className='btn btn-sm btn-light-primary fw-bold align-self-center' onClick={() => setEditApplication(true)}>Edit</button>
      </div>
      ), [editApplication, handleSubmit, handleDiscard])

  return newApp && (
    <>
      <SimpleCard
        title={<Title app={app} />}
        toolbar={<Toolbar />}
        error={state.error} className={className}>
        {state.loading &&
          <div className="d-flex fs-5 my-5">
            <Spinner animation="border" className="me-2" />
            <div>Loading...</div>
          </div>}
        <div className='d-flex align-items-center mb-10'>
          <div className='fw-bold fs-6 '>Account Manager</div>
          <div className="ms-3" style={{ width: '200px' }}>
            <SingleSelect
              isDisabled={!editApplication}
              isClearable={true}
              options={[currentUser]}
              getOptionLabel={o => `${o.firstname} ${o.lastname}`}
              getOptionValue={o => o.id}
              value={users.find(u => u.id === newApp.am_id)}
              onChange={v => setState({ ...state, data: { ...newApp, am_id: v.id } })} />
          </div>
        </div>
        {/* <h4>MMP Sources</h4> */}

        {/* <div className='row d-flex align-items-center my-3'>
      <label className='col-1 col-sm-2 form-label required fw-bold fs-6' htmlFor="appName">Name</label>
      <div className='col-8 col-sm-7'>
        <input type='text' name="name" id="appName" autoComplete='false'
            className='form-control form-control-sm'
            value={newApp?.name}
            placeholder='Application name'
            disabled={!editApplication}
            onChange={e => setState({...state, data: {...newApp, name: e.target.value}})}
        />
      </div>
      <div className="col-3">
        <SingleSelect name="timezone"
          isDisabled={!editApplication}
          options={timezoneCodes}
          value={timezoneCodes.find(ds => ds.value === newApp.timezone)}
          onChange={v => setState({...state, data: {...newApp, timezone: v.value}})}
        />
      </div>
    </div>
    <div className='row my-3'>
      <label className='col-1 col-sm-2 form-label required fw-bold fs-6' htmlFor="appLogo">Logo</label>
      <div className='col-11 col-sm-10'>
        <input type='text' name="logo" id="appLogo"
          className='form-control form-control-sm'
          value={newApp?.logo || ''}
          placeholder='Application logo'
          disabled={!editApplication}
          onChange={e => setState({...state, data: {...newApp, logo: e.target.value}})}
        />
      </div>
    </div> */}
        {newApp.sources.map((s, i) => (
          <div className='d-flex align-items-center mb-5' key={i}>
            <label className='form-label fw-bold fs-6 me-3 py-0 my-0'>Source {i + 1}</label>
            <div style={{ width: '160px' }} className="d-flex align-items-center ms-3">
              <SingleSelect name="mmp"
                placeholder="MMP"
                isDisabled={!editApplication}
                options={dsOptions}
                value={dsOptions.find(ds => ds.value.mmp === s.mmp && ds.value.account === s.account)}
                onChange={v => handleUpdateSource(i, 'mmp', v.value)} />
            </div>
            <div className="ms-5">
              <input type='text' name="code"
                className='form-control form-control-sm'
                placeholder='code'
                disabled={true}
                value={s.code}
                onChange={e => handleUpdateSource(i, 'code', e.target.value)} />
            </div>
            <button className='btn btn-sm fw-bold btn-outline-primary ms-3 my-0 fs-7'
              disabled={!editApplication || !s.mmp || !!s.sync}
              onClick={() => handleImportOptions(i, s.mmp, s.account)}
            >
              <i className="bi bi-box-arrow-in-right" />
              <span>Import</span>
            </button>
            <div className="form-check form-switch ms-3 my-0">
              <input disabled={!editApplication} className="form-check-input" type="checkbox" role="switch" id={`enableSource${i}`} checked={s.is_active} onChange={e => handleUpdateSource(i, 'is_active', e.target.checked)} />
              <label className="form-check-label" htmlFor={`enableSource${i}`}>Enable</label>
            </div>
            <div className="ms-5 py-0 text-muted fs-7">Last sync on {s.sync ? moment.utc(s.sync).local().format('DD/MM/YYYY HH:mm') : 'N/A'}</div>
            {!newApp.is_active && s.mmp === 'adjust' &&
              <button className='btn btn-sm fw-bold btn-outline-primary ms-3 my-0 fs-7' onClick={() => setUploadModal({show: true, source: s})}>
                <i className="bi bi-cloud-upload" />
                <span>Upload</span>
              </button>}

          </div>))}

        <div className='row mb-3'>
          <button className='col-1 col-sm-2 btn btn-sm btn-link fw-bold d-flex justify-content-start ms-3'
            disabled={!allowAddSource()}
            onClick={handleAddSource}
          >+ Add Source</button>
        </div>
        {/* {editApplication ?
    <div className="d-flex">
      <button className='btn btn-sm btn-light-primary fw-bold align-self-center me-3' onClick={handleSubmit}>Save</button>
      <button className='btn btn-sm btn-light-default fw-bold align-self-center' onClick={handleDiscard}>Discard</button>
    </div> :
    <div className="d-flex">
      <button className='btn btn-sm btn-light-primary fw-bold align-self-center' onClick={() => setEditApplication(true)}>Edit</button>
    </div>} */}
      </SimpleCard>
      <ImportModal
        show={importModal.show}
        mmp={importModal.mmp}
        account={importModal.account}
        index={importModal.index}
        onClose={() => setImportModal({ ...importModal, show: false })}
        onSubmit={handleImportSubmit} />
      <UploadModal show={uploadModal.show} source={uploadModal.source} onClose={() => setUploadModal({ ...uploadModal, show: false })} />
    </>
  )
}

const Title = ({app}) => (
  <div className="d-flex align-items-center">
    {app.logo &&
    <img className="me-3 mt-3" src={app.logo} alt="logo" height="100" />}
    <div>
      <h1>{app.name}</h1>
      <div className='text-muted fs-6'>Timezone: {app.timezone || 'UTC'}</div>
    </div>
    
  </div>
)

const ImportModal = ({onClose, show, mmp, account, index, onSubmit}) => {
  // const { importOptions } = useSelector(s => s.applications);
  // const [sources, setSources] = useState([]);
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState([]);
  const [state, setState] = useState({loading: false, error: null, data: []})
  // const [error, setError] = useState(null);
  const [app, setApp] = useState(null);
  
  useEffect(() => {
    if (!show) { return; }
    const params = {mmp, account}
    setState(s => ({...s, loading: true}));
    axios.get('application/import', {params})
    .then(({data}) => {
      setState({loading: false, error: null, data});
      // setError(null);
      // setSources(data);
    })
    .catch(error => setState(s => ({...s, error: errorHandler(error)})));
  }, [mmp, show, account]);

  useEffect(() => {
    if (!search) { 
      setOptions(state.data);
    }
    else {
      const fo = state.data.filter(o => 
        o.name.toLowerCase().includes(search.toLowerCase()) 
        || o.code.toLowerCase().includes(search.toLowerCase()));
      
      setOptions(fo); 
    }
  }, [state.data, search]);

  return (
      <Modal show={show} onHide={onClose} centered>
          <Modal.Header closeButton>
              <Modal.Title className='d-flex'>
                {state.loading && <Spinner animation="border" className="me-2"/>}
                Import Application
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
            <div className="mb-3 form-group">
              <div className='col-12 fv-row'>
                <input type='text' 
                  className='form-control' 
                  value={search} 
                  placeholder='Search...' 
                  onChange={e => setSearch(e.target.value)} 
                />
              </div>
            </div>
            <div className="mb-10 form-group">
              {!!options && options.map(o => (
              <div key={o.code} className="form-check form-check-custom form-check-solid mt-3">
                <input className="form-check-input" type="radio" name="application" id={o.code} onChange={() => setApp(o)} />
                <label className="form-check-label" htmlFor={o.code}>{o.name} ({o.code})</label>
              </div>))}
            </div>
            <div className='d-flex justify-content-end mb-3'>
              <button disabled={state.loading || state.error || !app} className='btn btn-primary btn-sm me-3' onClick={() => onSubmit(index, {...app, account})}>Save</button>
              <button className='btn btn-default btn-sm' onClick={onClose}>Discard</button>
            </div>
          </Modal.Body>
      </Modal>
  )
}

const UploadModal = ({show, onClose, source}) => {
  const [state, setState] = useState({loading: false, error: null, data: null});
  const [attr, setAttr] = useState('ua');
  const [eventType, setEventType] = useState('unique');

  const handleAttrChange = useCallback((e) => setAttr(e.target.value),[]);
  const handleEventTypeChange = useCallback((e) => setEventType(e.target.value),[]);
  
  const handleSubmit = useCallback(() => {
    setState(s => ({...s, loading: true}));
    if (state.data !== null) {
      const payload = new FormData();
      const metadata = {
        source,
        attribution: attr,
        eventType,
      }
      payload.append("file", state.data);
      payload.append("metadata", JSON.stringify(metadata));
      axios.post('sync/upload', payload)
      .then(() => {
        setState(s => ({...s, loading: false, error: null}));
        onClose()
      })
      .catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
    }
  }, [attr, eventType, source, onClose, state.data]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        {state.loading && <Spinner animation="border" className="me-2"/>}
        <Modal.Title>Upload Data File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
        <div className='mb-5'>
          <div>MMP: <b>{source?.mmp} ({source?.account})</b></div>
          <div className='d-flex mt-2 '>
            <div>Event Type</div>
            <b>
            <div className="form-check form-check-inline ms-3">
              <input className="form-check-input" type="radio" name="importEventTypeOptions" id="inlineRadioUnique" value="unique" 
                checked={eventType === 'unique'} onChange={handleEventTypeChange} />
              <label className="form-check-label" htmlFor="inlineRadioUnique">Unique</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="importEventTypeOptions" id="inlineRadioCounter" value="counter" 
                checked={eventType === 'counter'} onChange={handleEventTypeChange}/>
              <label className="form-check-label" htmlFor="inlineRadioCounter">Counter</label>
            </div>
            </b>
          </div>
          <div className='d-flex mt-2'>
            <div>Attribution</div>
            <b>
            <div className="form-check form-check-inline ms-3">
              <input className="form-check-input" type="radio" name="importAttrOptions" id="inlineRadioUA" value="ua" 
                checked={attr === 'ua'} onChange={handleAttrChange} />
              <label className="form-check-label" htmlFor="inlineRadioFirst">UA</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="importAttrOptions" id="inlineRadioRT" value="rt" 
                checked={attr === 'rt'} onChange={handleAttrChange}/>
              <label className="form-check-label" htmlFor="inlineRadioRT">Retargeting</label>
            </div>
            </b>
          </div>
        </div>
        <input type="file" name="dataFile"  accept=".csv" onChange={e => setState({...state, data: e.target.files[0]})} />
        <div className='d-flex justify-content-end mb-3'>
          <button disabled={!state.data} className='btn btn-primary btn-sm me-3' onClick={handleSubmit}>Upload</button>
          <button className='btn btn-default btn-sm' onClick={onClose}>Discard</button>
        </div>
      </Modal.Body>
    </Modal>

  )
}