import axios from 'axios';
// import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { ApplicationItem } from '../common';
import { formatNumber, defaultCol } from "../../core/helpers";
import { errorHandler } from '../../core/network';
import { setApplication } from '../toolbar/toolbarRedux';

const ApplicationColumn = ({data}) => {
  const dispatch = useDispatch();
  return <ApplicationItem 
    name={data.name} 
    logo={data.logo} 
    className='mt-3'
    onClick={() => dispatch(setApplication(data.id))} 
  />
}

const columnDefs = [{
    field: 'name', 
    headerName: 'Application',
    filter: true,
    pinned: 'left',
    cellRenderer: ({data}) => <ApplicationColumn data={data} />,
  },
  {...defaultCol, field: 'impressions'},
  {...defaultCol, field: 'clicks'},
  {...defaultCol, field: 'conversions'},
  {...defaultCol, field: 'cr1', headerName: 'Conversion %', valueFormatter: ({value}) => formatNumber(value * 100, 2) + '%'},
  {...defaultCol, field: 'events', headerName: 'Payable events'},
  {...defaultCol, field: 'cr2', headerName: 'Payable events %', valueFormatter: ({value}) => formatNumber(value * 100, 2) + '%'},
  {...defaultCol, field: 'revenue'},
  {...defaultCol, field: 'profit'},
  {...defaultCol, field: 'margin', valueFormatter: ({value}) => formatNumber(value * 100, 2) + '%'},
]

export const ApplicationList = ({startDate, endDate, className}) => {
  const gridRef = useRef();
  const [applications, setApplications] = useState([]);
  const [state, setState] = useState({loading: false, error: null});

  useEffect(() => {
      setState(s => ({...s, loading: true}));
      axios.get('stat/applications', {params: {startDate, endDate}})
      .then(({data}) => {
          setApplications(data);
          setState({loading: false, error: null});
          gridRef.current.api.hideOverlay();
      })
      .catch(error => {
          setState({loading: false, error: errorHandler(error)});
      });
  }, [startDate, endDate])

  const handleGridReady = () => {
      if (state.loading) {
          gridRef.current.api.showLoadingOverlay();
      }
  }

  return (
    // <SimpleCard title="Applications" className={className} loading={state.loading} error={state.error}>
    <div className={`ag-theme-alpine ${className}`} style={{width: '100%', height: 500}}>
      <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
      <AgGridReact
        ref={gridRef}
        rowData={applications} 
        columnDefs={columnDefs} 
        rowHeight={70} 
        suppressContextMenu={true}
        onFirstDataRendered={e => e.columnApi.autoSizeAllColumns()}
        overlayLoadingTemplate={'<span class="ag-overlay-loading-center fs-1 mx-5 my-5">Loading...</span>'}
        onGridReady={handleGridReady}
      />
    </div>
    // </SimpleCard>
  )
}