import moment from 'moment'
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { setToolbarType } from "../components/toolbar/toolbarRedux";
import { usePageData } from "../_metronic/layout/core";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { SimpleCard } from "../components/card";
import { Alert, Spinner } from "react-bootstrap";
import axios from "axios";
import { errorHandler } from "../core/network";
import _ from "lodash";
import { defaultCol, formatNumber } from "../core/helpers";
import { KpiRibbon, KpiChart } from '../components/dashboard';
import { PivotTable } from '../components/PivotTable';


const columns = [
  {field: 'date', filter: true, enableRowGroup: true, enablePivot: true, width: 130, cellRenderer: ({value}: {value:string}) => moment(value).format('DD/MM/YYYYY')},
  {field: 'provider.name', headerName: 'Provider', filter: true, enableRowGroup: true, enablePivot: true},
  {field: 'geo', filter: true, enableRowGroup: true, enablePivot: true},
  {field: 'supply.name', headerName: 'Supply', filter: true, enableRowGroup: true, enablePivot: true},
  {field: 'feed', filter: true, enableRowGroup: true, enablePivot: true},
  {...defaultCol, field: 'total', headerName: 'Total Searches'},
  {...defaultCol, field: 'monetized', headerName: 'Total Monetized'},
  {...defaultCol, field: 'clicks'},
  {...defaultCol, field: 'revenue'},
  {...defaultCol, field: 'cost', width: 120},
  {...defaultCol, field: 'profit', width: 120},
]


export const SearchAnalytics:FC = () => {
  const { type } = useSelector((s:RootState) => s.toolbar);
  // const {data} = useSelector((s:RootState) => s.search);
  const { setPageTitle, setPageBreadcrumbs } = usePageData()
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>()

  const [state, setState] = useState({loading: false, error: null, data: null});

  useEffect(() => {
    setPageTitle('Analytics - Search');
    setPageBreadcrumbs([]);
  }, [setPageTitle, setPageBreadcrumbs]);
  
  useEffect(() => {
    if (type != 'search') {
      dispatch(setToolbarType('search'));
    }
  }, [dispatch, type]);


  useEffect(() => {
    const params = {
      start: searchParams.get('start'),
      end: searchParams.get('end')
    }
    if (!params.start || !params.end) { return; }
    setState(s => ({...s, loading: true}));
    axios.get('/search/analytics', {params})
    .then(({data}) => setState(s => ({...s, loading: false, error: null, data})))
    .catch(error => setState(s => ({...s, loading: false, error: errorHandler(error)})));
  }, [searchParams, setSearchParams]);

  return <>
    {state.loading && 
    <div className="d-flex fs-5">
        <Spinner animation="border" className="me-2"/>
        <div>Please wait...</div>
    </div>}
    <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
    <div className="d-flex justify-content-between mb-10">
        <KpiRibbon value={state.data?.revenue?.value} text="Revenue" />
        <KpiRibbon value={state.data?.profit?.value} text="Profit" />
        <KpiRibbon value={state.data?.revenue?.rr} text="Revenue Run Rate" />
        <KpiRibbon value={state.data?.profit?.rr} text="Profit Run Rate" />
    </div>
    <KpiChart data={state.data?.data} height={400} className="mt-5 mb-10" />
    <PivotTable data={state.data?.data} columns={columns} />
  </>
}


