import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToolbarType } from "../components/toolbar/toolbarRedux";
import { AppDispatch } from "../store";
import { usePageData } from "../_metronic/layout/core";
import { UnknownPublishers } from '../components/notifications';

// type MissingRulesPublisher = {
//   publisher_id: number;
//   publisher: string,
//   application_id: number;
//   application: string;
//   country: string;
//   os: string;
//   mmp: string;
//   first_event: string;
//   last_event: string;
//   conversions: number;
//   rule_id:number;
// }

export function NotificationsPage() {
  const { setPageTitle, setPageBreadcrumbs } = usePageData();
  const dispatch:AppDispatch = useDispatch();
  // const [missingRules, setMissingRules] = useState<MissingRulesPublisher[]>();

  useEffect(() => {
    setPageTitle('Notifications');
    setPageBreadcrumbs([]);
    dispatch(setToolbarType(null));

    // axios.get('publisher/missing_rules')
    // .then(({data}) => {
    //   setMissingRules(data);
    // })
    // .catch(error => {
    //   console.log(error);
    // });
  }, [setPageTitle, setPageBreadcrumbs, dispatch]);

  return (
  <>
  <div className='row'>
    <div className='col-12'>
      <UnknownPublishers />
    </div>
  </div>
  {/* <div className="row">
    <div className="col-6">
      <SimpleCard title="Missing Supply Rules" loading={!missingRules}>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th>Application</th>
                <th>Publisher</th>
                <th>Country</th>
                <th>OS</th>
                <th>MMP</th>
                <th>Conversions</th>
                <th>Last event</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {missingRules && missingRules.map((r, i) => (
              <tr key={i}>
                <td><Link to={`/applications/${r.application_id}?ruleType=supply&publisher=${r.publisher_id}&demand=${r.rule_id}`}>{r.application}</Link></td>
                <td>{r.publisher}</td>
                <td>{r.country}</td>
                <td>{r.os}</td>
                <td>{r.mmp}</td>
                <td>{formatNumber(r.conversions)}</td>
                <td>{moment(r.last_event).format('DD/MM/YYYY')}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SimpleCard>
    </div>
  </div> */}
  </>
  )
}