import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../demo/dashboard/DashboardWrapper'
import {MenuTestPage} from '../demo/MenuTestPage'
import {getCSSVariableValue} from '../_metronic/assets/ts/_utils'
import {WithChildren} from '../_metronic/helpers'
import BuilderPageWrapper from '../demo/layout-builder/BuilderPageWrapper'
import { 
  DashboardPage, 
  StatisticsPage, 
  ApplicationsPage, 
  ApplicationDetailsPage, 
  NotificationsPage, 
  OptimizationPage,
  SearchSettingsPage,
  SearchProviderPage,
  SearchAnalytics,
} from '../pages'

import { useDispatch } from 'react-redux'
import { getApplications } from '../components/application/appRedux'
import { getAdvertiser } from '../components/advertisers/advRedux'
import { getProviders } from '../components/search/searchRedux';
import { AppDispatch } from '../store'
import { getPublishers, getUsers } from '../components/common'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getApplications());
    dispatch(getAdvertiser());
    dispatch(getPublishers());
    dispatch(getProviders());
    dispatch(getUsers())
  }, [dispatch]);


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        
        {/* Pages */}
        <Route path='dashboard' element={<DashboardPage />} />
        <Route path='notifications' element={<NotificationsPage />} />
        <Route path='statistics' element={<StatisticsPage />} />
        <Route path='analytics/optimization' element={<OptimizationPage />} />
        
        <Route path='applications/:id' element={<ApplicationDetailsPage />} />
        <Route path='applications' element={<ApplicationsPage />} />

        <Route path='settings/search/:id' element={<SearchProviderPage />} />
        <Route path='settings/search' element={<SearchSettingsPage />} />

        <Route path='analytics/search' element={<SearchAnalytics />} />


        
        {/* DEMO pages */}
        <Route path='demo/dashboard' element={<DashboardWrapper />} />
        <Route path='demo/builder' element={<BuilderPageWrapper />} />
        <Route path='demo/menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='demo/crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='demo/crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='demo/crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='demo/crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='demo/apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='demo/apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
