import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit'

export interface ToolbarState {
  type?: 'dashboard' | 'statistics' | 'applications' | 'analytics' | 'search',
  appId?: number,
  os?:string,
  publisherId?: number,
  dateRange : {
    startDate: string,
    endDate: string,
    label?: string,
  }
}

const initialState:ToolbarState = {
    type: 'dashboard',
    dateRange: {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      label: 'MTD',
    }
}

export const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange.startDate = action.payload.startDate;
      state.dateRange.endDate = action.payload.endDate;
      state.dateRange.label = action.payload.label;
    },
    setApplication: (state, action) => {
      state.appId = action.payload;
    },
    setOs: (state, action) => {
      state.os = action.payload;
    },
    setPublisher: (state, action) => {
      state.publisherId = action.payload;
    },
    setToolbarType: (state, action) => {
        state.type = action.payload;
    }
  },
});


// Action creators are generated for each case reducer function
export const { setDateRange, setApplication, setToolbarType, setOs, setPublisher } = toolbarSlice.actions
export default toolbarSlice.reducer