import { FC, ReactNode, useCallback } from "react";
import { Alert, Spinner } from "react-bootstrap";

type CardHeaderProps = {
  title?: string | ReactNode,
  subtitle?: string | ReactNode,
  toolbar?: ReactNode,
  loading?: boolean,
}

const CardHeader:FC<CardHeaderProps> = ({title, subtitle, toolbar, loading}) => {
  if (!(title || toolbar || loading)) { return <></>}
  return (
    <div className='card-header border-0'>
      <h3 className='card-title align-items-start flex-column'>
        <div className="d-flex">
          {loading && <Spinner animation="border" className="me-2"/>}
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </div>
        <span className='text-muted mt-1 fw-semibold fs-7'>{subtitle}</span>
      </h3>
      <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
        {toolbar}
      </div>
    </div>
  )
}

type CardBodyProps = {
  error?: string | null,
  children: ReactNode
}

const CardBody:FC<CardBodyProps> = ({error, children}) => (
  <div className={`card-body pt-2`}>
    <Alert variant="danger" show={!!error}>{error}</Alert>
    {children}
  </div> 
)


type Props = {
  title?: string | ReactNode,
  error?: string | null,
  subtitle?: string | ReactNode,
  toolbar?: ReactNode,
  loading?: boolean,
  className?: string,
  children: ReactNode
} 

export const SimpleCard:FC<Props> = (props) => {
  const {title, subtitle, toolbar, loading, error, className, children} = props;
  
  return (
    <div className={`card ${className}`}>
      <CardHeader title={title} subtitle={subtitle} toolbar={toolbar} loading={loading} />
      <CardBody error={error}>
        {children}
      </CardBody>
    </div>
  )
}

type CollapseProps = Props & {id: string, show?: boolean} 

export const CollapseCard:FC<CollapseProps> = (props) => {
  const {title, subtitle, toolbar, loading, error, className, children, id, show} = props;
  
  const Title = useCallback(() => (
    <button className="btn btn-link fs-5" data-bs-toggle="collapse" data-bs-target={`#${id}`} aria-expanded="true" aria-controls={id}>
      <span>{title}<i className="ms-1 bi bi-caret-down-fill" /></span> 
      {/* <span className='ms-10'>{advertisers.find(a => a.id === brief.advertiser) || 'N/A'}</span> */}
    </button>
  ), [title, id]);

  return (
    <div className={`card ${className}`}>
      <CardHeader title={<Title />} subtitle={subtitle} toolbar={toolbar} loading={loading} />
      <div id={id} className={`collapse ${show && 'show'}`}>
        <CardBody error={error}>
          {children}
        </CardBody>
      </div>
    </div>
  )
}