// import { FC } from 
// type Props = {
//   start: str,
//   end:str,
//   application_id:number,
//   os: 'ios' | 'android',
//   publisher_id:number,
//   publisher_code:str
// }

import axios from "axios";
import { useEffect, useState } from "react"
import Chart from "react-apexcharts";
import { errorHandler } from "../../core/network";

// const FraudReason:FC<Props> = (params) => {
//   return <></>
// }

export const FraudReason = ({params}) => {
  const [state, setState] = useState({loading: false, error: null, data: []});
  const [chart, setChart] = useState({options: {}, series: []});

  useEffect(() => {
    setState(s => ({...s, loading:true}))
    axios.get('/optimization/fraud_reasons', {params})
    .then(({data}) => {
      setState({loading: false, error: null, data})
    }).catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
  }, [params]);

  useEffect(() => {
    const data = state.data;
    if (!data) { return; }
    setChart({
      series: data.map(d => d.rt + d.pa),
      options: {
        chart: {type: 'pie'},
        legend: {position: 'bottom'},
        labels: data.map(d => {
          const words = d.reason.split('_').map(w => w[0].toUpperCase() + w.slice(1));
          return words.join(" ");
        }),
      }
    });
  }, [state.data])

  return chart ?
    <Chart options={chart.options} series={chart.series} type="pie" style={{height: "250px"}} /> :
    <></>
}