import moment from "moment"
// import { useEffect } from "react"
import { useSelector } from "react-redux"
import { SimpleCard } from "../card"

import {KTSVG} from '../../_metronic/helpers'
import { ApplicationItem } from "../common"
import { useNavigate } from "react-router-dom"

export const ApplicationsDrawer = () => {
  const {data, loading, error} = useSelector(s => s.applications);
  const navigate = useNavigate()

  const toolbar = [
    <button key={0} className='btn btn-sm btn-icon btn-active-light-primary me-n5' id='kt_activities_close'>
      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
    </button>
  ]

  return (
  <div
    id='kt_activities'
    className='bg-body'
    data-kt-drawer='true'
    data-kt-drawer-name='activities'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_activities_toggle'
    data-kt-drawer-close='#kt_activities_close'
  >
    <SimpleCard 
      title="Applications" 
      loading={loading} 
      error={error}
      toolbar={toolbar}
      // subtitle={fetch && fetch.format('DD/MM/YYYY HH:mm')} 
    >
      <div className="row position-relative scroll-y">
        {data && data.map(d => (
        <div key={d.id} className="col-4 my-4 cursor-pointer">
          <ApplicationItem 
            name={d.name} 
            logo={d.logo} 
            logoSize={50} 
            info={d.sync ? moment.utc(d.sync).local().format('DD/MM/YYYY HH:mm') : 'N/A'} 
            onClick={() => navigate('/applications/' + d.id)} 
          />
        </div>))}
      </div>
    </SimpleCard>
  </div>)
}
