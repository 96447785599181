/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
// import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../../helpers'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store';

const SidebarMenuMain = () => {
  // const intl = useIntl()
  const { missingCodes } = useSelector<RootState>(s => s.publishers);

  return (
    <>
      <SidebarMenuItem 
        to='/dashboard' 
        icon='/media/icons/duotune/general/gen008.svg' 
        title='Dashboard' 
        fontIcon='bi-layers'
      />

      <SidebarMenuItem 
        to='/notifications' 
        icon='/media/icons/duotune/general/gen007.svg' 
        title='Notifications' 
        fontIcon='bi-layers'
        hasAlert={!!missingCodes && missingCodes.length > 0}
      />
      
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Analytics</span>
        </div>
      </div>
      <SidebarMenuItem 
        to='/statistics' 
        icon='/media/icons/duotune/general/gen017.svg' 
        title='Statistics' 
        fontIcon='bi-layers'
      />
      <SidebarMenuItem 
        to='/analytics/optimization' 
        icon='/media/icons/duotune/general/gen002.svg' 
        title='Optimization' 
        fontIcon='bi-layers'
      />
      <SidebarMenuItem 
        to='/analytics/search' 
        icon='/media/icons/duotune/graphs/gra004.svg' 
        title='Search' 
        fontIcon='bi-layers'
      />

      
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
        </div>
      </div>
      <SidebarMenuItem 
        to='/applications' 
        icon='/media/icons/duotune/general/gen019.svg' 
        title='Applications' 
        fontIcon='bi-layers'
      />
      <SidebarMenuItem 
        to='/settings/search' 
        icon='/media/icons/duotune/general/gen004.svg' 
        title='Search' 
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItem to='/rules' icon='/media/icons/duotune/general/gen019.svg' title='Rules' fontIcon='bi-layers'/>
      <SidebarMenuItem to='/publishers' icon='/media/icons/duotune/abstract/abs001.svg' title='Publishers' fontIcon='bi-layers'/> */}
      
      {/* DEMO:begin 
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Demo</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/demo/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/demo/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <SidebarMenuItemWithSub
        to='/demo/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/demo/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/demo/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/demo/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/demo/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/demo/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/demo/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/demo/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/demo/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/demo/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/demo/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/demo/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/demo/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/demo/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/demo/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/demo/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/demo/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/demo/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/demo/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/demo/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/demo/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/demo/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/demo/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/demo/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/demo/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
      {/* DEMO:end */}
    </>
  )
}

export {SidebarMenuMain}
