import _ from 'lodash';
import moment, { Moment } from "moment"
import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux"
import Select, { components, OptionProps, SingleValueProps } from "react-select";
import { RootState } from "../../store"
import { DateRangePicker } from "../DateRangePicker"
import { ApplicationItem } from "../common";
import { osOptions } from "../../core/helpers";


export const getParams = (params:URLSearchParams) => {
  let newParams:any = {}
  params.forEach((value, key) => {
    newParams[key] = value;
  });
  return newParams;
}

export const setParam = (params:URLSearchParams, key:string, value:string) => {
  return {...getParams(params), [key]: value};
}

export const removeParam = (params:URLSearchParams, key:string | string[]) => {  
  return _.omit(getParams(params), key)
}

type ApplicationOption = {
  id: number,
  name: string,
  logo: string,
}

const Option = (props:OptionProps<ApplicationOption>) => (
    <components.Option {...props}>
      <ApplicationItem name={props.data.name} logo={props.data.logo} />
    </components.Option>
)

const SingleValue = ({children, ...props}: SingleValueProps<ApplicationOption>) => {
  return (
  <components.SingleValue {...props}>
    <ApplicationItem name={props.data.name} logo={props.data.logo} logoSize={30} />
  </components.SingleValue>
)};


export const ToolbarSearch = () => {
  const apps = useSelector((s:RootState) => s.applications);
  const publishers = useSelector((s:RootState) => s.publishers);
  const [params, setParams] = useSearchParams();
  const [label, setLabel] = useState('');
  
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
  }, []);

  useEffect(() => {
    const start = params.get('start');
    const end = params.get('end');
    if (!start || !end) { 
      let np = {};
      if (!start) {
        const startDate = moment().startOf('month').format('YYYY-MM-DD');
        np = setParam(params, 'start', startDate);
      }
      if (!end) {
        const endDate = moment().format('YYYY-MM-DD');
        np = {...np, ...setParam(params, 'end', endDate)}
      }
      setLabel('MTD');
      setParams(np);
    }
    else {
      const startDate = moment(start);
      const endDate = moment(end);
      if (moment().startOf('month').diff(startDate, 'days') === 0 && endDate > moment()) {
        setLabel("MTD");
      }
      else if (moment().subtract(1, 'month').startOf('month').diff(startDate, 'days') === 0 && moment().subtract(1, 'month').endOf('month').diff(endDate, 'days') === 0) {
        setLabel("LM");
      }
      else if (moment().startOf('year').diff(startDate, 'days') === 0 && endDate > moment()) {
        setLabel("YTD");
      }
      if (!params.get('application')) {
        params.delete('os');
        params.delete('publisher');
        setParams(params);
      }
    }
  }, [params, setParams]);

  const handlePeriod = useCallback((startDate: Moment, endDate: Moment) => {
    setParams({...getParams(params), 
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
    });
  }, [setParams, params]);

  const handleSelect = useCallback((value:any, param:string, property?:string) => {
    setParams(value ? setParam(params, param, value[property || 'id']) : removeParam(params, param));
  }, [setParams, params])


  return (
    <div className='d-flex justify-content-between w-100'>
      <div className="d-flex align-items-center">
      {/* <div className="me-5" style={{width: '300px'}}>
        <Select
          className="basic-single w-100"
          placeholder="Applications"
          options={apps.data || []}
          components={{Option, SingleValue}}
          getOptionLabel={o => o.name}
          getOptionValue={o => o.id.toString()}
          isClearable={true}
          isSearchable={true}
          onChange={v => handleSelect(v, 'application')}
          value={apps.data?.find(d => d.id && d.id.toString() === params.get('application')) || null}
        />
      </div>
      <div className="me-5" style={{width: '150px'}}>
        <Select
          isDisabled={!params.get('application')}
          className="basic-single w-100"
          placeholder="OS"
          options={osOptions}
          isClearable={true}
          onChange={v => handleSelect(v, 'os', 'value')}
          value={osOptions.find(o => o.value === params.get('os')) || null}
        />
      </div>
      <div className="me-5" style={{width: '200px'}}>
        <Select
          isDisabled={!params.get('application')}
          className="basic-single w-100"
          placeholder="Publishers"
          options={publishers.data || []}
          getOptionLabel={o => o.name}
          getOptionValue={o => o.id.toString()}
          isClearable={true}
          isSearchable={true}
          onChange={v => handleSelect(v, 'publisher')}
          value={publishers.data?.find(d => d.id.toString() === params.get('publisher')) || null}
        />
      </div> */}
      </div>
      <div className='d-flex align-items-center flex-shrink-0'>        
        {/* <div className='bullet bg-secondary h-35px w-1px mx-5'></div> */}
        <div className='flex-shrink-0 '>
          <ul className='nav'>
            <li className='nav-item'>
              <button 
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ' + (label === 'MTD' ? 'active': '')}
                data-bs-toggle='tab'
                onClick={() => handlePeriod(moment().startOf('month'), moment())}
              >This month</button>
            </li>

            <li className='nav-item'>
              <button
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ' + (label === 'LM' ? 'active' : '')}
                data-bs-toggle='tab'
                onClick={() => handlePeriod(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'))}
              >Last month</button>
            </li>

            <li className='nav-item'>
              <button
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ' + (label === 'YTD' ? 'active' : '')}
                data-bs-toggle='tab'
                onClick={() => handlePeriod(moment().startOf('year'), moment())}
              >This year</button>
            </li>
          </ul>
        </div>
        <DateRangePicker 
          className="form-control form-control-sm"
          startDate={moment(params.get('start'))} 
          endDate={moment(params.get('end'))}
          onCallback={handlePeriod}
        />      
      </div>
    </div>
  )
}