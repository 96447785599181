import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../../store';
import { errorHandler } from '../../core/network';

type OsType = 'ios' | 'android';

type DataSourceType = {
  mmp:string; 
  code: string; 
  os: OsType;
  sync: string | null; 
  is_active:boolean;
  account:string;
}

type RuleType = {
  id: number | null;
  start: string;
  end?: string;
  rate?: number;
  rate_fn?: string;
  currency: string;
  os: string;
  type: string;
  application_id: number;
  advertiser_id: number;
  publisher_id: number;
  campaigns: {id: number}[];
  countries: string[];
  events: string[];
}

export type ApplicationType = {
  id: number | null;
  name: string;
  timezone?: string;
  logo?: string;
  sources: DataSourceType[];
  sync:string | null;
  rules?: RuleType[],
  statistics?: {
    countries: string[],
    events: string[],
    missingPublishers: {id: number, code: string} [],
    publishers: {
      id:number, 
      name:string, 
      conversions:number, 
      revenue:number, 
      cost:number, 
      campaigns:{
        ios:{id:number, code:string}[],
        android:{id:number, code:string}[],
      }[]
    }[]
  }
}


type ApplicationsState = {
  loading: boolean;
  data: ApplicationType[] | null;
  error: string | null;
  fetch: string | null;
}

const initialState:ApplicationsState = {
    loading: false,
    data: null,
    error: null,
    fetch: null,
}

export const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    fetch: (state) => { 
      state.loading = true; 
    },
    success: (state, action) => {
      // state.fetch = moment();
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const getApplications = () => async (dispatch:AppDispatch) => {
  try {
    dispatch(appSlice.actions.fetch());
    const res = await axios.get('application/');
    dispatch(appSlice.actions.success(res.data));
  }
  catch (error:any) {
    dispatch(appSlice.actions.failure(errorHandler(error)));
  }
}

export default appSlice.reducer