import _ from 'lodash';
import axios from "axios";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { Alert, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getApplications, ApplicationType } from "../components/application/appRedux";
import { setToolbarType } from "../components/toolbar/toolbarRedux";
import { AppDispatch, RootState } from "../store";
import { usePageData } from "../_metronic/layout/core";
import { ApplicationBrief, ApplicationDetails} from '../components/application';
import { SimpleCard } from "../components/card";
import { ApplicationItem } from "../components/common";
import { State } from "../core/types";
import { errorHandler } from "../core/network";
import { KTSVG } from '../_metronic/helpers';

export const ApplicationsPage:FC = () => {
  const {loading, data, error} = useSelector((s:RootState) => s.applications);
  const { type } = useSelector((s:RootState) => s.toolbar);
  const [newAppModal, setNewAppModal] = useState(false);

  const { setPageTitle, setPageBreadcrumbs } = usePageData()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setPageTitle('Applications');
    setPageBreadcrumbs([]);
  }, [setPageTitle, setPageBreadcrumbs]);

  useEffect(() => {
    if (!!type) {
      dispatch(setToolbarType(null));
    }
  }, [dispatch, type])


  return (
    <>
    <Alert variant="danger" show={!!error}>{error}</Alert>
    <div className="d-flex justify-content-between mb-3">
      <div>
        {loading && 
        <div className="d-flex fs-5">
          <Spinner animation="border" className="me-2"/>
          <div>Loading...</div>
        </div>}
      </div>
      <button disabled={loading} className='btn btn-sm fw-bold btn-light-primary' onClick={() => setNewAppModal(true)}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
        <span>New Application</span>
      </button>
    </div>
    <div className="row">
      {!!data && data.map(d => (
      <div key={d.id} className="col-4 mb-4">
        <Link to={`/applications/${d.id}`} style={{ textDecoration: 'none' }}>
          <SimpleCard>
            <ApplicationItem name={d.name} logo={d.logo} className="mt-3 fs-5" />
            {d.sources.map(s => s.is_active && (
              <div className="d-flex mt-2" key={`${s.code}_${s.mmp}_${s.account}`} >
                <div className="me-3">
                  <img src={`/media/custom/${s.mmp}-icon.png`} alt={s.mmp} height='25px'/>
                  <span className="ms-1 fs-8">{s.account}</span>
                </div>
                {s.os && <div className="me-3"><img src={`/media/custom/${s.os}.png`} alt={s.os} height='25px'/></div>}
                {s.sync && <div className="me-3">{moment.utc(s.sync).local().format('DD/MM/YYYY HH:mm')}</div>}
                {(!s.sync || moment.utc().diff(moment.utc(s.sync), 'hour') > 4) && <div className="text-danger">sync required</div>}
                {/* <div>{error}</div> */}
              </div>))}
          </SimpleCard>
        </Link>
      </div>))}
    </div>
    <NewAppModal show={newAppModal} onClose={() => setNewAppModal(false)} onUpdate={() => dispatch(getApplications())}/>
    </>
  )
}

export const ApplicationDetailsPage:FC = () => {
  const { id } = useParams()
  const { type } = useSelector((s:RootState) => s.toolbar);
  const applications = useSelector((s:RootState) => s.applications);
  const { setPageTitle, setPageBreadcrumbs } = usePageData();
  const dispatch:AppDispatch = useDispatch()
  const [app, setApp] = useState<any>();

  useEffect(() => {
    if (id) {
      const a = applications.data && applications.data.find(a => a.id === parseInt(id))
      if (!_.isEqual(app, a)) { 
        setApp(a);
      }
    }
  }, [applications, id, app]);

  
  useEffect(() => {
    if (!!type) {
      dispatch(setToolbarType(null));
    }
  }, [dispatch, type])

  useEffect(() => {
    if (app) {
      setPageTitle(app.name);
      setPageBreadcrumbs([{title: 'Applications', path: '/applications', isSeparator: false, isActive: true}]);
    }
  }, [app, setPageTitle, setPageBreadcrumbs])

  return app ? (
    <>
    <ApplicationDetails className="mb-10" app={app} onUpdate={() => dispatch(getApplications())} />
    <ApplicationBrief app={app} />
    </>
  ) : <></>
}


type NewAppModalProps = {
  show: boolean,
  onClose: () => void,
  onUpdate: () => void
}
const NewAppModal:FC<NewAppModalProps> = ({show, onClose, onUpdate}) => {
  const [state, setState] = useState<State>({loading: false, error: null, data: ''});
  
  const handleSubmit = useCallback(() => {
    const newApp:ApplicationType = {id: null, name: state.data, sources: [], sync: null}
    setState(s => ({...s, loading: true}));
    axios.post('application/', newApp)
    .then(() => {
      setState({loading: false, error: null, data: state.data});
      onUpdate()
      onClose()
    })
    .catch(e => setState({loading: false, error: errorHandler(e), data: state.data}))
  }, [onClose, onUpdate, state.data]);

  return (
    <Modal id="delRuleModal" show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='d-flex'>
            {state.loading && <Spinner animation="border" className="me-2"/>}
            <span>New Application</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
        <div className='row mb-3'>
          <label className='col-2 col-form-label required fw-bold fs-6'>Name</label>
          <div className='col-10 fv-row'>
            <input type='text' 
              className='form-control form-control-lg' 
              value={state.data} 
              placeholder='Application name' 
              onChange={e => setState({...state, data:e.target.value})} 
            />
          </div>
        </div>
        <div className='d-flex justify-content-end mb-3'>
          <button className='btn btn-primary me-3' onClick={handleSubmit}>Save</button>
          <button className='btn btn-default' onClick={onClose}>Discard</button>
        </div>
      </Modal.Body>
    </Modal>  
  )
}