import _ from 'lodash';
import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import { Alert } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { formatNumber, defaultCol } from "../../core/helpers";
import { errorHandler } from '../../core/network';

const columnDefs = [{
    field: 'name', 
    filter: true,
    pinned: 'left',
    headerName: 'Publisher',
    cellStyle: {...defaultCol.cellStyle, justifyContent: 'start'},
    rowGroup: true,
    hide: true,
  },
  {
    field: 'code', 
    filter: true,
    headerName: 'PID',
    cellStyle: {...defaultCol.cellStyle, justifyContent: 'start'},
  },
  {...defaultCol, field: 'impressions'},
  {...defaultCol, field: 'clicks'},
  {...defaultCol, field: 'conversions'},
  {...defaultCol, field: 'cr1', headerName: 'Conversion %', aggFunc: aggCR1, valueFormatter: ({value}) => formatNumber(value * 100, 2) + '%'},
  {...defaultCol, field: 'events', headerName: 'Payable events'},
  {...defaultCol, field: 'cr2', headerName: 'Payable events %', aggFunc: aggCR2, valueFormatter: ({value}) => formatNumber(value * 100, 2) + '%'},
  {...defaultCol, field: 'revenue'},
  {...defaultCol, field: 'profit'},
  {...defaultCol, field: 'margin', valueFormatter: ({value}) => formatNumber(value * 100, 2) + '%'},
]

function aggCR1(params) {
  const rows = params.rowNode.childrenAfterGroup.map(c => c.data);
  const clicks = _.sumBy(rows, 'clicks')
  // const cr = clicks > 0 ? _.sumBy(rows, 'conversions') / clicks : 'N/A';
  return _.sumBy(rows, 'conversions') / clicks;
}

function aggCR2(params) {
  const rows = params.rowNode.childrenAfterGroup.map(c => c.data);
  return _.sumBy(rows, 'events') / _.sumBy(rows, 'conversions');
}



export const PublisherList = ({startDate, endDate, application, className}) => {
  const gridRef = useRef();
  const [applications, setApplications] = useState([]);
  const [state, setState] = useState({loading: false, error: null});

  useEffect(() => {
      setState(s => ({...s, loading: true}));
      axios.get('stat/publishers', {params: {startDate, endDate, application}})
      .then(({data}) => {
          setApplications(data);
          setState({loading: false, error: null});
          gridRef.current.api.hideOverlay();
      })
      .catch(error => {
          setState({loading: false, error: errorHandler(error)});
      });
  }, [startDate, endDate, application])

  const handleGridReady = () => {
      if (state.loading) {
          gridRef.current.api.showLoadingOverlay();
      }
  }

  return (
    // <SimpleCard title="Applications" className={className} loading={state.loading} error={state.error}>
    <div className={`ag-theme-alpine ${className}`} style={{width: '100%', height: 500}}>
      <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
      <AgGridReact
        ref={gridRef}
        rowData={applications} 
        columnDefs={columnDefs} 
        suppressAggFuncInHeader={true}
        // rowHeight={70} 
        suppressContextMenu={true}
        onFirstDataRendered={e => e.columnApi.autoSizeAllColumns()}
        overlayLoadingTemplate={'<span class="ag-overlay-loading-center fs-1 mx-5 my-5">Loading...</span>'}
        onGridReady={handleGridReady}
        // aggFuncs={aggFuncs}
      />
    </div>
    // </SimpleCard>
  )
}