import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Alert, Spinner, Modal } from "react-bootstrap";
import {useAuth} from '../../modules/auth';
import { CollapseCard, SimpleCard } from "../card";
import { DateRangePicker } from "../DateRangePicker";
import { eventLabel, countryCodes, osOptions, currencyCodes, eventTypeOptions } from '../../core/helpers';
import { errorHandler } from "../../core/network";
import { MultiSelect, SingleSelect } from '../common/SelectWrapper';
import Select from 'react-select';

// const MAX_HISTORY_MONTH = 5

export const ApplicationBrief = ({app}) => {
  const [briefs, setBriefs] = useState([]);
  const [delModal, setDelModal] = useState({show: false, brief: null, ruleId: null});
  const [createDemandModal, setCreateDemandModal] = useState({show: false});
  const {currentUser} = useAuth();

  const [state, setState] = useState({loading: false, error:null, data:null});
  const [stat, setStat] = useState({loading: false, error:null, data:null});
  
  const [period, setPeriod] = useState({
    start: moment().startOf('month'),
    end: moment().endOf('month')
  })
  const [disableActions, setDisableActions] = useState(false);
  const advertisers = useSelector(s => s.advertisers.data);
  
  const fetchStat = useCallback((appId) => {
    if (!appId) { return; }
    setStat(s => ({...s, loading: true}))
    axios.get('application/stat', {params: {app: appId}})
    .then(({data}) => {
      setStat({loading: false, error: null, data});
    })
    .catch(e => setStat(s => ({...s, loading: false, error: errorHandler(e)})));
  }, [])

  const fetchData = useCallback((appId, start, end) => {
    if (!appId || !start || !end) { 
      return console.error("fetchData: wrong input parameters");
    }
    setState(s => ({...s, loading: true}))
    const params = {
      app: appId,
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
    }
    
    axios.get('application/briefs', {params})
    .then(({data}) => {
      setState({loading: false, error: null, data});
      setBriefs(_.cloneDeep(data.briefs));
      setDisableActions(false);
    })
    .catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
  }, []);

  useEffect(() => fetchStat(app.id), [app, fetchStat]);

  useEffect(() => fetchData(app.id, period.start, period.end), [app, period, fetchData]);

  
  //  Revert to initial state
  const handleDiscard = useCallback(() => {
    setBriefs(_.cloneDeep(state.data.briefs));
    setDisableActions(false);
  }, [state.data])


  // duplicate either demand or supply rule
  const handleDuplicate = useCallback((rule, drid, aid) => {
    setDisableActions(true);
    let brief = briefs.find(b => b.advertiser.id === aid);
    if (rule.type === 'demand') {
      brief.rules.push({demand: {...rule, id: null}, supply: []});
    }
    else if (rule.type === 'supply') {
      let dr = brief.rules.find(r => r.demand.id === drid);
      dr.supply.push({...rule, id: null});
    }
    setBriefs([...briefs]);
  }, [briefs]);


  // create new supply rule derived from existing demand rule
  const handleCreateSupply = useCallback((rule) => {
    let brief = briefs.find(b => b.advertiser.id === rule.advertiser_id);
    let dr = brief.rules.find(r => r.demand.id === rule.id);
    dr.supply.push({...rule, id: null, type: 'supply', advertiser_id: null});
    setBriefs([...briefs]);
  }, [briefs]);

  // create new demand rule
  const handleCreateDemand = useCallback((aid) => {
    let brief = briefs.find(b => b.advertiser.id === aid);
    if (!brief) {
      brief = {
        start: period.start,
        end: period.end,
        advertiser: advertisers.find(a => a.id === aid),
        rules: []
      }
      briefs.push(brief);
    }
    brief.rules.push({
      demand: {
        advertiser_id: aid, 
        start: period.start,
        end: period.end,
        type: 'demand',
        application_id: app.id,
        is_retargeting: false,
        events: [],
        campaigns: [{code: 'Any', id: 'any'}],
        countries: [],
      },
      supply: []
    });
    setBriefs([...briefs]);
  }, [briefs, period, app, advertisers]);
  
  // removes rule from the list until and fetch data to refresh
  const handleRemoveRule = useCallback((brief, ruleId) => {
    if (brief) {
      let nb = briefs.find(b => b === brief);
      let index = nb.rules.findIndex(r => r.demand.id === ruleId);
      if (index >= 0) {
        nb.rules.splice(index, 1);
      }
      else {
        for (let i=0; i < nb.rules.length; i++) {
          index = nb.rules[i].supply.findIndex(r => r.id === ruleId);
          if (index >= 0) {
            nb.rules[i].supply.splice(index, 1);
            break;
          }
        }
      }
      setBriefs([...briefs]);
    }
    fetchData(app.id, period.start, period.end)
  }, [briefs, app, period, fetchData]);

  // update statistics data
  const handleUpdateStat = useCallback(() => {
    setStat(s => ({...s, loading: true}));
    const payload = {
      appid: app.id,
      start: period.start.format('YYYY-MM-DD'),
      end: period.end.format('YYYY-MM-DD')
    }
    axios.post('stat/update', payload)
    .then(() => {
      fetchStat();
      setStat(s => ({...s, loading: false, error: null}));
    })
    .catch(e => setStat(s => ({...s, loading: false, error: errorHandler(e)})));
  }, [app, period, fetchStat])


  const Toolbar = useCallback(({start, end, aid}) => (
      <button disabled={disableActions || state.loading} 
        className='btn btn-sm btn-light-primary fw-bold' 
        onClick={() => setCreateDemandModal({show: true, start, end, aid})}
      >
        <i className="bi bi-plus" />Demand Rule
      </button>
  ), [disableActions, state.loading]);
  
  return (
    <>
      {stat.data && stat.data.missingPublishers.length > 0 &&
      <Alert className="text-dark" variant='warning' show={true}>Unknown publishers: {stat.data.missingPublishers.map(p => p.code).join(', ')}</Alert>}

      <SimpleCard  className='mb-10'>
        <div className='d-flex align-items-center justify-content-between mt-6'>
          <div className="d-flex align-items-center">
            {state.loading && <Spinner animation="border" className="me-2"/>}
            <button className='btn btn-sm btn-link'
              onClick={() => setPeriod({
                start: moment(period.start).subtract(1, 'month').startOf('month'), 
                end: moment(period.start).subtract(1, 'month').endOf('month')})}>
              <i className="bi bi-caret-left-fill" />
            </button>
            <div className='fw-bold fs-3 me-2'>{moment(period.start).format('MMM YYYY')}</div>
            <button className='btn btn-sm btn-link'
              disabled={period.end.diff(moment(), 'month') > 1} 
              onClick={() => setPeriod({
                start: moment(period.start).add(1, 'month').startOf('month'), 
                end: moment(period.start).add(1, 'month').endOf('month')})}>
              <i className="bi bi-caret-right-fill" />
            </button>
          </div>
          
          <div className="d-flex align-items-center">
            <Toolbar start={period.start} end={period.end} aid={null} />
            {currentUser.lastname === 'Vaidman' &&
            <button className='btn btn-sm btn-light-primary fw-bold ms-3' 
              disabled={state.loading || stat.loading}
              onClick={handleUpdateStat}>
              <i className="bi bi-arrow-repeat" /> Statistics
            </button>}
            
            <button className='btn btn-sm btn-light-primary fw-bold ms-3'
              disabled={state.loading || stat.loading}
              onClick={() => setPeriod({...period})}
            >
              <i className="bi bi-arrow-clockwise" />Refresh
            </button>
          </div>
        </div>
      </SimpleCard>
      <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
      <Alert variant="danger" show={!!stat.error}>{stat.error}</Alert>
      {briefs && briefs.map((brief, i) => 
      <CollapseCard key={`brief${i}`} id={`brief${i}`} show={i === 0} 
          title={brief.advertiser.name} className='mb-5' 
          toolbar={<Toolbar start={brief.start} end={brief.end} aid={brief.advertiser.id} />}
        >
          {/* {rules[brief.adv.id].map(r => (!r.end || (moment(r.start).diff(brief.start, 'days') >= 0 && moment(r.end).diff(brief.end, 'days') <= 0)) && */}
          {brief.rules.map(r => 
          <div key={`rule${r.demand.id}`} id={`rule${r.demand.id}`} className='row mb-3'>
            <div className='col-6 border p-3 overflow-scroll'>
              <RuleDetails rule={r.demand} stat={stat} disableActions={disableActions} 
                onUpdate={() => fetchData(app.id, period.start, period.end)} 
                onDelete={() => setDelModal({show: true, brief, ruleId: r.demand.id})}
                onDiscard={handleDiscard}
                onDuplicate={() => handleDuplicate(r.demand, r.demand.id, brief.advertiser.id)} 
                onCreate={handleCreateSupply}
              />
            </div>
            <div className='col-6 border p-3 overflow-scroll' style={{maxHeight:'500px'}}>
              {r.supply && r.supply.map((sr, i) =>
              <div key={`rule${sr.id}`} id={`rule${sr.id}`}>
                {i !== 0 && <hr className='text-muted'/>}
                <RuleDetails dr={r.demand} rule={sr} stat={stat} disableActions={disableActions} 
                  onUpdate={() => fetchData(app.id, period.start, period.end)} 
                  onDelete={() => setDelModal({show: true, brief, ruleId: sr.id})}
                  onDiscard={handleDiscard} 
                  onDuplicate={() => handleDuplicate(sr, r.demand.id, brief.advertiser.id)}
                  onUpdateParent={() => fetchData(app.id, period.start, period.end)}
                  demandRules={state.data && _.flatten(state.data.briefs.map(b => b.rules.map(r => r.demand.id)))}
                  // onCreate={handleCreateSupply}
                />
              </div>)}
            </div>
          </div>)}
      </CollapseCard>)} 
      
      {state.data && state.data.unmatched.length > 0 &&
      <SimpleCard title="Unmatched supply rules">
      {state.data.unmatched.map(r => (
        <div className='mb-5' key={`rule${r.id}`}>
          <hr className='text-muted'/>
          <RuleDetails rule={r} stat={stat} disableActions={disableActions} 
            onUpdate={() => fetchData(app.id, period.start, period.end)} 
            onDelete={() => setDelModal({show: true, ruleId: r.id})}
            onDiscard={handleDiscard}
            onUpdateParent={() => fetchData(app.id, period.start, period.end)}
            demandRules={state.data && _.flatten(state.data.briefs.map(b => b.rules.map(r => r.demand.id)))}

            // onDuplicate={() => handleDuplicate(r, null, null)} 
            // onCreate={handleCreateSupply}
          />
        </div>))}
      </SimpleCard>}
      <CreateDemandRuleModal show={createDemandModal.show} aid={createDemandModal.aid} 
        onClose={() => setCreateDemandModal({...createDemandModal, show: false})} 
        onCreate={handleCreateDemand} 
      />
      <DeleteRuleModal show={delModal.show} ruleId={delModal.ruleId} onUpdate={() => handleRemoveRule(delModal.brief, delModal.ruleId)} 
        onClose={() => setDelModal({show: false, rule: null})}
      />
    </>
  )
}

const RuleDetails = ({stat, dr, rule, disableActions, onUpdate, onDuplicate, onDelete, onCreate, onDiscard, onUpdateParent, demandRules}) => {
  const [newRule, setNewRule] = useState(null);
  const [state, setState] = useState({loading: false, error: null, data: null});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setNewRule({...rule, start: moment(rule.start), end: moment(rule.end)});
    if (!rule.id) {
      setEdit(true);
      const el = document.getElementById('rulenull');
      if (el) {
        // el.scrollIntoView({behavior: 'smooth'})
        const y = el.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }
  }, [rule]);

  const handleAction = useCallback((action) => {
    switch(action) {
      case 'edit':
        return setEdit(true);
      case 'duplicate':
        return onDuplicate(rule);
      case 'delete':
        return onDelete(rule);
      case 'create':
        return onCreate(rule);
      default:
        console.error("unknown action " + action)
    }
  }, [onDuplicate, onDelete, onCreate, rule]);

  const handleSubmit = useCallback(() => {
    setState(s => ({...s, loading: true}))
    axios.post(`application/rule`, {...newRule,
      start: newRule.start.format('YYYY-MM-DD'),
      end: newRule.end.format('YYYY-MM-DD'),
      campaigns: newRule.campaigns.map(c => c.id),
      rule_type: newRule.type,
      supply: null,
    })
    .then(({data}) => {
      setState({loading: false, error: null, data})
      setEdit(false);
      onUpdate(data);
    })
    .catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
  }, [newRule, onUpdate])

  const disableSubmit = useMemo(() => !(!!newRule
    && ((newRule.type === 'demand' && !!newRule.advertiser_id) || (newRule.type === 'supply' && !!newRule.publisher_id))
    // && !!newRule.os 
    && !!newRule.start 
    && newRule.events.length > 0 
    && newRule.countries.length > 0 
    && (newRule.rate !== 0 || !!newRule.rate_fn)
    && !!newRule.currency
    && newRule.campaigns.length > 0
    && (newRule.events.find(e => e === 'cohort_revenue') ? newRule.cohort > 0 : true)),
    [newRule])

  const disableDropdown = useMemo(() => edit || state.loading || disableActions, [edit, state, disableActions]);

  const handleDiscard = useCallback(() => {
    setEdit(false);
    onDiscard()
  }, [onDiscard]);

  const showElement = useCallback((name) => {
    if (!newRule || !dr) { 
      return true;
    }
    else if (newRule.type === 'demand') {
      // if (name === 'period') {
      //   return brief.start.diff(newRule.start, 'days') !== 0 
      //     || !newRule.end 
      //     || brief.end.diff(newRule.end, 'days') !== 0;
      // }
      // else if (name === 'campaigns') {
      //   return newRule.campaigns.length !== 1 || newRule.campaigns[0].id !== 'any';
      // }
    }
    else if (newRule.type === 'supply') {
      if (edit) {return true; }
      if (name === 'period') {
        return moment(dr.start).diff(newRule.start, 'days') !== 0 
          || !newRule.end 
          || moment(dr.end).diff(newRule.end, 'days') !== 0;
      }
      else if (name === 'os') {
        return !dr.os && newRule.os;
      }
      else if (name === 'countries') {
        return !_.isEqual(newRule.countries.sort(), dr.countries.sort());
      }
      else if (name === 'events') {
        return !_.isEqual(newRule.events.sort(), dr.events.sort()) || newRule.is_retargeting !== dr.is_retargeting;
      }
      else if (name === 'campaigns') {
        return newRule.campaigns[0].id !== 'any' 
        || _.intersection(newRule.campaigns.map(c => c.id), dr.campaigns.map(c => c.id)).length !== newRule.campaigns.length;
      }
    }
    else {
      console.error('unknown rule type ' + newRule.type);
    }
    return true;
  }, [dr, newRule, edit]);

  if (!newRule) { return <></>}
  return (
    <>
      <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
      <Alert variant="danger" show={!!stat.error}>{stat.error}</Alert>
      <div className='d-flex w-100'>
        <RuleActionsDropdown disabled={disableDropdown} disableDuplicate={!onDuplicate} rule={newRule} onAction={handleAction} />
        {showElement('period') && 
        <RulePeriod className="ms-2" disabled={!edit} rule={newRule} onChange={setNewRule} />}
        {newRule?.type === 'supply' &&
        <RulePublisher className="ms-2 w-100" rule={newRule} stat={stat.data} disabled={!edit} onChange={setNewRule} />}
      </div>
      {showElement('os') &&
      <RuleOs className="mt-2" rule={newRule} disabled={!edit} onChange={setNewRule} />}
      {showElement('countries') &&
      <RuleCountries className="mt-2" rule={newRule} stat={stat.data} disabled={!edit} onChange={setNewRule} />}
      {showElement('campaigns') &&
      <RuleCampaigns className="mt-2" rule={newRule} stat={stat.data} disabled={!edit} onChange={setNewRule} />}
      {showElement('events') &&
      <RuleEvents className="mt-2" rule={newRule} stat={stat.data} disabled={!edit} onChange={setNewRule} />}
      <div className='d-flex justify-content-between mt-2'>
        <RulePayout className="me-3" rule={newRule} disabled={!edit} onChange={setNewRule} />
        <RuleCAP rule={newRule} disabled={!edit} onChange={setNewRule} />
      </div>
      {edit &&
      <div className='mt-3 d-flex'>
        <button disabled={disableSubmit || state.loading} className='btn btn-sm btn-primary me-3' onClick={handleSubmit}>
          {state.loading && <Spinner animation="border me-2 spinner-border-sm"/>}
          Save
        </button>
        <button disabled={state.loading} className='btn btn-sm btn-default' onClick={handleDiscard}>Discard</button>
      </div>}
      {newRule.type === 'supply' && (!newRule.parent_id || edit) &&
      <SetParent className="mt-3" rule={newRule} parent={dr} onUpdate={onUpdateParent} demandRules={demandRules} edit={edit} />}
    </>
  )
}

const SetParent = ({rule, parent, demandRules, onUpdate, className, edit}) => {
  const [parentOpt, setParentOpt] = useState(null);
  const [state, setState] = useState({loading: false, error: null});

  useEffect(() => {
    if (parent) {
      setParentOpt({id: parent.id, label: parent.id});
    }
  }, [parent]);

  const handleSubmit = useCallback((dr) => {
    setState(s => ({...s, loading: true}))
    axios.post('application/rule/parent', {demand: dr, supply: rule.id})
    .then(() => {
      setState({loading: true, error: null})
      onUpdate()
    })
    .catch(e => setState({loading: false, error: errorHandler(e)}));
  }, [rule, onUpdate])

  return (
    <div className={className}>
      {parent && !edit ?
      <div className='d-flex align-items-center'>
        <div>Is this rule related to demand rule #{parent.id} ?</div>
        <button className='btn btn-sm btn-light-primary ms-3'
          onClick={() => handleSubmit(parent.id)}
        >Confirm</button>
      </div> :
      <div className='d-flex align-items-center'>
        {edit && parent ?
        <div>Parent demand rule</div> :
        <div>Please set related demand rule</div>}
        <div className="ms-3" style={{width: '200px'}}>
          <SingleSelect
            placeholder="Select demand rule..."
            options={demandRules.map(r => ({label: r, value: r}))}
            value={parentOpt}
            onChange={v => setParentOpt(v)}
          />
        </div>
        <button className='btn btn-sm btn-light-primary ms-3' 
          disabled={!parentOpt}
          onClick={() => handleSubmit(parentOpt.value)}>
            {state.loading && <Spinner animation="border me-2 spinner-border-sm"/>} Confirm
          </button>
    </div>}
  </div>)
}


const RuleActionsDropdown = ({rule, disabled, disableDuplicate, onAction}) => (
  <div className='dropdown' style={{width: '125px'}}>
    <button type='button' 
      className='btn btn-sm btn-light-primary dropdown-toggle' 
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      id={`dropdown_rule${rule.id}`}>
      {/* {loading ? <Spinner animation="border"/> : `#${rule.id}`} {rule.type} */}
      #{rule.id || 'new'} {rule.type}
    </button>
    <ul className='dropdown-menu' aria-labelledby={`dropdown_rule${rule.id}`}>
      <li><button disabled={disabled} className='btn dropdown-item' onClick={() => onAction('edit')}>Edit</button></li>
      <li><button disabled={disabled || disableDuplicate} className='btn dropdown-item' onClick={() => onAction('duplicate')}>Duplicate</button></li>
      <li><button disabled={disabled} className='btn dropdown-item' onClick={() => onAction('delete')}>Delete</button></li>
      <li><hr className="dropdown-divider" /></li>
      <li><button disabled={disabled || rule.type === 'supply'} className='btn dropdown-item' onClick={() => onAction('create')}>+ Supply</button></li>
    </ul>
  </div>
)


const RulePeriod = ({rule, onChange, disabled, className}) => (
  <div className={className} style={{width:'180px'}}>
    <DateRangePicker className="form-control form-control-sm text-nowrap"
      isDisabled={disabled}
      startDate={rule.start} endDate={rule.end} 
      onCallback={(start, end) => onChange({...rule, start, end})}
      disabled={disabled}
    />
  </div>
)

const RuleOs = ({rule, onChange, disabled, className}) => (
  <div className={`${className} d-flex align-items-center`} style={{minWidth:'200px'}}>
    <label className='form-label fs-7 my-0 me-17'>OS</label>
    <MultiSelect name="os"
      isClearable={false}
      isDisabled={disabled}
      placeholder="OS"
      options={osOptions} 
      value={rule.os ? osOptions.find(t => t.value === rule.os) : osOptions} 
      onChange={os => onChange({...rule, os: os.length === 1 ? os[0].value : null})}
    />
  </div>
)

const RuleCountries = ({rule, onChange, stat, disabled, className}) => {
  // const [options, setOptions] = useState(countryCodes);
  
  // useEffect(() => {
  //   if (stat.countries) {
  //     const options = countryCodes.filter(cc => stat.countries?.findIndex(c => c === cc.value) >= 0);
  //     if (options.length > 0) {
  //       setOptions(options);
  //       return;
  //     }
  //   }
  //   setOptions(countryCodes);
  // }, [stat])

  return (
    <div className={`${className} d-flex align-items-center`} style={{minWidth:'200px'}}>
      <label className='form-label fs-7 my-0 me-5'>Countries</label>
      <MultiSelect
        isDisabled={disabled}
        placeholder="Countries"
        options={countryCodes}
        value={rule.countries?.map(c => countryCodes.find(co => co.value === c))}
        onChange={v => onChange({...rule, countries: v.map(c => c.value)})}
      />
    </div>
  )
}

const RulePublisher = ({rule, onChange, stat, disabled, className}) => {
  const publishers = useSelector(s => s.publishers.data);

  return publishers && (
    <div className={className} style={{minWidth:'150px'}}>
    {/* <label className='form-label fs-6'>Publisher</label> */}
    <SingleSelect 
      isDisabled={disabled}
      options={publishers}
      value={publishers.find(p => p.id === rule.publisher_id)}
      onChange={p => onChange({...rule, publisher_id: p.id})}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.id}
    />
  </div>    
  )
}

const superOptions = [{code: 'Any', id: 'any'}, {code: 'Select All', id: 'all'}]
const RuleCampaigns = ({rule, onChange, stat, disabled, className}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!stat) { return; }
    if (rule.type === 'demand') {
      setOptions([...superOptions, ..._.flatten(stat.publishers.map(p => p.campaigns.filter(c => !rule.os || c.os === rule.os)))]);
    }
    else if (rule.type === 'supply') {
      const pub = stat.publishers.find(p => p.id === rule.publisher_id);
      if (pub) {
        setOptions([...superOptions, ...pub.campaigns.filter(c => !rule.os || c.os === rule.os)]);
      }
    }
  }, [rule.type, rule.os, rule.publisher_id, stat]);

  const handleChange = useCallback((values) => {
    if (values.find(o => o.id === 'all')) {
      onChange({...rule, campaigns: options.slice(2)})
    }
    else if (values.find(o => o.id === 'any')) {
      if (rule.campaigns.find(c => c.id !== 'any')) {
        onChange({...rule, campaigns: [options[0]]});
      }
      else {
        const index = values.findIndex(o => o.id === 'any');
        if (index >= 0 && rule.campaigns.length > 0) {
          values.splice(index, 1);
        }
        onChange({...rule, campaigns: values})
      }
    }
    else {
      onChange({...rule, campaigns: values})
    }
  }, [rule, options, onChange])
  
  return (
    <div className={`${className} d-flex align-items-center`} style={{minWidth:'300px'}}>
      <label className='form-label fs-7 my-0 me-2'>Campaigns</label>
      <MultiSelect
        placeholder="Campaigns"
        isDisabled={disabled || (rule.type === 'supply' && !rule.publisher_id)}
        // closeMenuOnSelect={false}
        options={options}
        getOptionLabel={o => o.code}
        getOptionValue={o => o.id}
        value={rule.campaigns}
        onChange={handleChange}
      />
    </div>
  )
}

const RuleEvents = ({rule, onChange, stat, disabled, className}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!stat) { return; }
    let opts = stat.events.map(e => ({id: e, name: eventLabel(e)}));
    // let opts = [...options];
    // stat.events.forEach(e => {
    //   if (!opts.find(o => o.id === e)) {
    //     opts.push({id: e, name: eventLabel(e)});
    //   }
    // });
    rule.events.forEach(e => {
      if (!opts.find(o => o.id === e)) {
        opts.push({id: e, name: eventLabel(e)})
      }
    });
    setOptions(prev => [...prev, ...opts.filter(o => !prev.find(p => p.id !== o.id))]);
  }, [stat, rule.events]);

  const handleCreate = useCallback((inputValue) => {
    const counter = inputValue + '__counter';
    const unique =  inputValue + '__unique';
    const newOptions = [
      {id: counter, name: eventLabel(counter)},
      {id: unique, name: eventLabel(unique)},
    ]
    setOptions(prev => [...prev, ...newOptions]);
  }, [])

  return (
    <div className={`${className} d-flex align-items-center`}>
      <label className='form-label fs-7 my-0 me-10'>Events</label>
      <div className='d-flex align-items-top w-100'>
        <div className="w-100">
          <MultiSelect
            creatable={true}
            onCreateOption={handleCreate}
            placeholder="Events"
            isDisabled={disabled}
            options={options}
            value={rule.events?.map(e => options && options.find(eo => eo.id === e))}
            onChange={v => onChange({...rule, events: v.map(e => e.id)})}
            getOptionLabel={a => a.name}
            getOptionValue={a => a.id}
          />
        </div>
        {/* <button className='btn btn-sm btn-light-primary ms-2'><i className="bi bi-plus" /></button> */}
        <div className="ms-3" style={{minWidth:'100px'}}>
          <SingleSelect
            placeholder="Event Type"
            isDisabled={disabled}
            options={eventTypeOptions}
            value={rule.is_retargeting ? eventTypeOptions[0] : eventTypeOptions[1]}
            onChange={v => onChange({...rule, is_retargeting: v.value === 'rt'})}
          />
        </div>
        {rule.events.findIndex(e => e.startsWith('cohort_')) >= 0 &&
        <div className="d-flex align-items-center ms-5">
          <label className='form-label fs-7 text-nowrap my-0 me-2'>cohort (days)</label>
          <div className='' style={{width:'100px'}}>
            <input disabled={disabled} type="number" className='form-control form-control-sm' name="cohort" 
              value={rule.cohort} 
              onChange={e => onChange({...rule, cohort: parseInt(e.target.value)})} />
          </div>
        </div>}
      </div>
    </div>
  )
}

const RulePayout = ({rule, onChange, disabled, className}) => (
  <div className={`${className} d-flex align-items-center ${rule.rate_fn && 'w-100'}`}>
    <label className='form-label fs-7 my-0 me-5'>Payout</label>
    <div className="ms-5 w-100">
      {!rule.rate_fn ? 
      <input disabled={disabled} type="number" className='form-control form-control-sm' name="rate" style={{minWidth:'70px', maxWidth: '100px'}}
        value={rule.rate} 
        onChange={e => onChange({...rule, rate: parseFloat(e.target.value)})}
      /> :
      <textarea className='form-control from-control-sm fs-8' name="rate_fn" disabled rows="7" value={rule.rate_fn}/> 
      // <div className='w-50'>
      //   <pre className=''>
      //     <code className="ms-0 ps-0">
      //     {rule.rate_fn}
      //     </code>
      //   </pre>
      // </div>
      }
    </div>
    <div className="ms-3" style={{minWidth:'90px'}}>
      <SingleSelect
        isDisabled={disabled}
        options={currencyCodes}
        value={currencyCodes.find(c => c.value === rule.currency)}
        onChange={v => onChange({...rule, currency: v.value})}
      />
    </div>
  </div>
)

const RuleCAP = ({rule, onChange, disabled, className}) => (
  <div className={'d-flex align-items-center ' + className}>
    <label className='form-label fs-7 my-0 me-3'>CAP</label>
    <input disabled={disabled} type="number" className='form-control form-control-sm' name="eventCap" style={{minWidth:'70px', maxWidth: '100px'}}
      value={rule.cap} 
      onChange={e => onChange({...rule, cap: e.target.value})}
    />
  </div>
)

const DeleteRuleModal = ({show, ruleId, onClose, onUpdate}) => {
  const [state, setState] = useState({loading: false, error: null, data: null});

  const handleSubmit = useCallback(() => {
    setState(s => ({...s, loading: true}))
    axios.delete(`application/rule/${ruleId}`)
    .then(() => {
      setState({loading: false, error: null, data: null});
      onUpdate(ruleId);
      onClose()
    })
    .catch(e => setState(s => ({...s, loading: false, error: errorHandler(e)})));
  }, [ruleId, onUpdate, onClose])
  
  return (
    <Modal id="delRuleModal" show={show} onHide={onClose} centered>
      <Modal.Body>
        <Alert variant="danger" show={!!state.error}>{state.error}</Alert>
        <div className='my-10'>
          <h3 className='d-flex justify-content-center mt-3 mb-5'>Are you sure you want to delete Rule #{ruleId}</h3>
          <div className='d-flex justify-content-center mb-3'>
            <button disabled={state.loading} className='btn btn-primary mx-3' onClick={handleSubmit}>Yes</button>
            <button disabled={state.loading} className='btn btn-default mx-3' onClick={onClose}>No</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const CreateDemandRuleModal = ({show, aid, onClose, onCreate}) => {
  const advertisers = useSelector(s => s.advertisers.data);
  const [adv, setAdv] = useState();
  
  useEffect(() => {
    if (aid) {
      setAdv(advertisers.find(a => a.id === aid))
    }
  }, [aid, advertisers]);

  const handleSubmit = useCallback(() => {
    onCreate(adv.id);
    onClose();
  }, [onCreate, onClose, adv]);

  return (
    <Modal id="createDemandRuleModal" show={show} onHide={onClose} centered>
      <Modal.Body>
        <div className='my-10'>
          <h3 className='d-flex justify-content-center mt-3 mb-5'>Select advertiser for new demand rule</h3>
          <div className='mb-5'>
          <Select
            placeholder="Advertiser..."
            options={advertisers}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            onChange={v => setAdv(v)}
            value={adv}
          />
          </div>
          <div className='d-flex justify-content-center mb-3'>
            <button disabled={!adv} className='btn btn-sm btn-primary mx-3' onClick={handleSubmit}>Create</button>
            <button className='btn btn-sm btn-default mx-3' onClick={onClose}>Discard</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}